export enum TimeUnitsType {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export enum ShortTimeUnitsType {
  DAYS = 'dd',
  HOURS = 'hh',
  MINUTES = 'mm',
  SECONDS = 'ss',
}
