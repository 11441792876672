import axios from 'axios';

import config from '@/config';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export function apiGetTemplates(query, filterByRole = true) {
  const endpoint = `${config.apiUrl}userapi/templates?filter_by_role=${filterByRole.toString()}`;
  return axios.get(endpoint, {
    params: query,
  });
}

export function apiGetTemplatesFolder(query, filterByRole = true) {
  const endpoint = `${
    config.apiUrl
  }userapi/template/folders?filter_by_role=${filterByRole.toString()}`;
  return axios.get(endpoint, {
    params: query,
  });
}

export function apiCreateFolder(parentId, folderName) {
  const endpoint = `${config.apiUrl}userapi/template/folders`;
  return axios.post(endpoint, {
    name: folderName,
    parent_folder: parentId,
  });
}

export function apiDeleteFolder(folderId) {
  const endpoint = `${config.apiUrl}userapi/template/folders/${folderId}`;
  return axios.delete(endpoint);
}

export function apiGetTemplate(id) {
  const endpoint = `${config.apiUrl}userapi/templates/${id}`;
  return axios.get(endpoint);
}

export function apiDeleteTemplate(id) {
  const endpoint = `${config.apiUrl}userapi/templates/${id}`;
  return axios.delete(endpoint);
}

export function apiUpdateTemplate(id, settings) {
  const endpoint = `${config.apiUrl}userapi/templates/${id}`;
  return axios.put(endpoint, {
    name: settings.name,
    settings,
  });
}

export function apiCreateTemplate(settings, folder = null) {
  const endpoint = `${config.apiUrl}userapi/templates`;
  return axios.post(endpoint, {
    name: settings.name,
    settings,
    folder,
  });
}

export function apiMoveTemplate(templateId, folderId) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}`;
  return axios.put(endpoint, {
    folder: folderId,
  });
}

export function apiMoveMultiTemplates(templateIds, folderId) {
  const endpoint = `${config.apiUrl}userapi/move-delete-templates`;
  return axios.patch(endpoint, {
    folder: folderId,
    template_ids: templateIds,
  });
}

export function apiDeleteTemplates(templateIds) {
  let idsPath = '';
  templateIds.forEach((template_id) => {
    idsPath += `&template_id=${template_id}`;
  });
  const endpoint = `${config.apiUrl}userapi/move-delete-templates?${idsPath}`;
  return axios.delete(endpoint);
}

export function apiUpdateTemplateFolder({ folderId, name }) {
  const endpoint = `${config.apiUrl}userapi/template/folders/${folderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(endpoint, {
        name,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
}

// ---- TEMPLATE CHILDREN ---- //

export function apiGetTemplateChildren(templateId) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children`;
  return axios.get(endpoint);
}

export function apiAttachTemplateChild({ templateId, widgetData }) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children`;
  return axios.post(endpoint, widgetData);
}

export function apiUpdateTemplateChild(templateId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children`;
  return axios.put(endpoint, widgetData);
}

export function apiRemoveChild(templateId, data) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children`;
  return axios.delete(endpoint, {
    data,
  });
}

export function apiReplaceChildItem(templateId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/children/replace`;
  return axios.put(endpoint, {
    ...widgetData,
  });
}

// ---- TEMPLATE ROLES ---- //

export function apiGetTemplateWidgetRoles(templateId) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/roles-access`;
  return axios.get(endpoint);
}

export function apiSaveTemplateWidgetRoles(templateId, roles) {
  const endpoint = `${config.apiUrl}userapi/templates/${templateId}/roles-access`;
  return axios.post(endpoint, { roles });
}

// ---- TEMPLATE OVERRIDES ---- //

export const apiGetScreenTemplateChildren = async (templateId, screenId, playlistId = '') => {
  return axios.get(
    `${config.apiUrl}userapi/screens/${screenId}/template/${templateId}/children${
      !!playlistId ? `?playlist_id=${playlistId}` : ''
    }`,
  );
};

export const apiGetScreenGroupTemplateChildren = async ({
  groupId,
  playlistId = '',
  templateId,
  layoutId,
}) => {
  return axios.get(
    `${config.apiUrl}userapi/screen-group/${groupId}/template/${templateId}/children${
      !!playlistId ? `?playlist_id=${playlistId}&` : '?'
    }${!!layoutId ? `layout=${layoutId}` : ''}`,
  );
};

export const apiCreateScreenTemplateItemOverride = async ({
  screenId,
  itemId,
  newItemId,
  templateId,
  overrideConfig = {},
  playlistId,
}) => {
  return axios.post(`${config.apiUrl}userapi/template-overrides`, {
    screen: screenId,
    original_item: itemId,
    template: templateId,
    local_item: newItemId,
    config: overrideConfig,
    playlist: playlistId,
  });
};

export const apiCreateScreenGroupTemplateItemOverride = async ({
  groupId,
  itemId,
  newItemId,
  templateId,
  overrideConfig = {},
  playlistId,
  layoutId,
}) => {
  return axios.post(`${config.apiUrl}userapi/template-overrides/screen-group`, {
    screen_group: groupId,
    original_item: itemId,
    template: templateId,
    local_item: newItemId,
    config: overrideConfig,
    playlist: playlistId,
    layout: layoutId,
  });
};

export const apiScreenUpdateTemplateItemOverride = async (
  { overrideId, itemId, newItemId, templateId, overrideConfig = {} },
  playlistId,
) => {
  const payload = {
    override_id: overrideId,
    original_item: itemId,
    template: templateId,
    config: overrideConfig,
    local_item: newItemId,
  };

  if (!!playlistId) {
    payload.playlist = playlistId;
  }

  return axios.put(`${config.apiUrl}userapi/template-overrides`, payload);
};

export const apiScreenGroupUpdateTemplateItemOverride = async (
  { overrideId, itemId, newItemId, templateId, overrideConfig = {}, groupId },
  playlistId,
) => {
  const payload = {
    override_id: overrideId,
    original_item: itemId,
    template: templateId,
    config: overrideConfig,
    local_item: newItemId,
    screen_group: groupId,
  };

  if (!!playlistId) {
    payload.playlist = playlistId;
  }

  return axios.put(`${config.apiUrl}userapi/template-overrides/screen-group`, payload);
};

export const apiScreenDeleteTemplateItemOverride = async (screenId, overrideId) => {
  return axios.delete(
    `${config.apiUrl}userapi/template-overrides/${screenId}?override_id=${overrideId}`,
  );
};

export const apiScreenGroupDeleteTemplateItemOverride = async (groupId, overrideId) => {
  return axios.delete(
    `${config.apiUrl}userapi/template-overrides/screen-group/${groupId}?override_id=${overrideId}`,
  );
};
