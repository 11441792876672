<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    class="modal"
    id="content-options-modal"
    title="Add new content to a playlist"
  >
    <template v-slot:body>
      <div class="content-options-container">
        <div class="content-option widget" @click="openBrowserContentModal">
          <div class="logo">
            <i class="material-icons-outlined align-middle">folder_open</i>
          </div>
          <div class="option-body">
            <div class="option-title">Browse media library</div>
            <div class="option-description">Choose media from your library</div>
          </div>
        </div>

        <div class="content-option widget" @click="openAppsModal">
          <div class="logo">
            <i class="material-icons-outlined align-middle">widgets</i>
          </div>
          <div class="option-body">
            <div class="option-title">Add new widget</div>
            <div class="option-description">
              Add dynamic functions, integrate with other services, calendar, clock, bus schedule,
              etc.
            </div>
          </div>
        </div>

        <div class="content-option widget" @click="openTemplateModal">
          <div class="logo">
            <i class="material-icons-outlined align-middle">photo_size_select_large</i>
          </div>
          <div class="option-body">
            <div class="option-title">Add new template</div>
            <div class="option-description">Add a template as an item to the playlist.</div>
          </div>
        </div>

        <div class="content-option widget" @click="openLinkModal">
          <div class="logo">
            <i class="material-icons-outlined align-middle">language</i>
          </div>
          <div class="option-body">
            <div class="option-title">Add a Link</div>
            <div class="option-description">Add a website link.</div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer><div></div> </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import SendButton from '@/components/common/SendButton.vue';
  import EventBus from '@/models/EventBus';

  export default {
    name: 'PlaylistContentModal',

    components: { Modal, SendButton },

    data() {
      return {
        showModal: false,
      };
    },

    mounted() {
      this.showModal = true;
    },

    methods: {
      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },

      openBrowserContentModal() {
        this.closeModal();
        EventBus.$emit('OPEN_CONTENT_BROWSER');
        this.$emit('openContentBrowser');
      },

      openAppsModal() {
        this.closeModal();
        this.$emit('openAppsModal');
      },

      openTemplateModal() {
        this.closeModal();
        this.$emit('openTemplateModal');
      },

      openLinkModal() {
        this.closeModal();
        this.$emit('openLinkModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    .modal-container {
      width: min(732px, 90vw);
    }
  }

  .content-options-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .content-option {
      display: grid;
      background-color: $backgroundGrey2;
      grid-template-columns: 48px auto;
      padding: 24px;
      text-align: left;
      cursor: pointer;
      transition: background 0.25s ease-in;

      &:hover {
        background-color: $backgroundGrey;
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 4px;
        color: $primaryRed;

        i {
          font-size: 50px;
        }
      }

      .option-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
      }

      .option-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }

    .widget {
      grid-column: 1 / 3;
    }

    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }

  .modal {
    .modal-header {
      font-size: 20px;
      line-height: 32px;
      background: none !important;
      border: none !important;

      position: relative;
      padding: 27px 0 19px;
      margin: 0 32px;
      color: $primaryText;

      @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
