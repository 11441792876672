<script lang="ts">
  export default {
    name: 'PermissionsManagerModal',
  };
</script>

<script setup lang="ts">
  import { ref, onMounted, computed } from 'vue';
  import Modal from '@/components/common/Modal.vue';
  import Loader from '@/components/common/Loader.vue';
  import { AccessRoles } from '@/types/api/roles';
  import { apiGetRoleAccess, apiSaveRoleAccess } from '@/api/roles';
  import { useToasted } from '@/helpers';
  import SwitchToggle from '@/components/common/Switch.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';

  const props = defineProps<{
    itemId: string | number;
    type: 'layout' | 'playlist' | 'playlistitem' | 'screen-groups' | 'screen' | 'templates';
  }>();

  const toasted = useToasted();

  const emit = defineEmits<{
    (event: 'close'): void;
  }>();

  const isLoading = ref<boolean>(false);
  const isSaving = ref<boolean>(false);
  const roles = ref<Array<AccessRoles>>([]);

  onMounted(async () => {
    isLoading.value = true;
    try {
      const response = await apiGetRoleAccess(props.itemId, props.type);
      roles.value = response.access_roles;
    } catch (err) {
      console.log('Failed to get roles access');
    }
    isLoading.value = false;
  });

  function close() {
    emit('close');
  }

  async function save() {
    isSaving.value = true;
    try {
      const access = roles.value.filter((role) => role.is_active).map((role) => role.id);
      await apiSaveRoleAccess(props.itemId, props.type, access);
      toasted.global.general_success({
        message: 'Role updated successfully',
      });
      close();
    } catch (err) {
      toasted.global.general_error({
        message: 'Error updating the role',
      });
    }
    isSaving.value = false;
  }

  const selectedRoles = computed({
    get() {
      const access: { [key: number]: boolean } = {};
      roles.value.forEach((role) => {
        access[role.id] = role.is_active;
      });
      return access;
    },
    set(newAccess: { [key: number]: boolean }) {
      roles.value.forEach((role) => {
        if (newAccess[role.id] !== undefined) {
          role.is_active = newAccess[role.id];
        }
      });
    },
  });

  function handlePermissionChange(roleId: number, value: boolean) {
    selectedRoles.value = {
      ...selectedRoles.value,
      [roleId]: value,
    };
  }
</script>

<template>
  <Modal
    @closeModal="close"
    title="Permissions"
    :okFunction="save"
    okButtonLabel="Save"
    :isSaving="isSaving"
    :modalStyles="{ width: '400px', 'min-height': '556px' }"
  >
    <template v-slot:body>
      <template v-if="isLoading">
        <Loader />
      </template>
      <template v-else>
        <div class="roles">
          <div v-for="role in roles" class="role-item" :key="role.id">
            <div class="left">
              <BaseText variant="subtitle2">{{ role.name }}</BaseText>
            </div>
            <div class="right">
              <div class="users-count">
                <span class="material-icons-outlined icon">person_outline</span>
              </div>
              <div class="toggle">
                <SwitchToggle
                  :value="selectedRoles[role.id]"
                  @input="(value) => handlePermissionChange(role.id, value)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
  .roles {
    display: flex;
    flex-direction: column;

    .role-item {
      width: 100%;
      border-bottom: 1px solid $lineGrey;
      padding: 14px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        gap: 6px;
        .icon {
          font-size: 20px;
          color: $primaryRed;
        }
      }
      .right {
        display: flex;
        align-items: center;
        gap: 26px;

        .users-count {
          display: flex;
          align-items: center;
          gap: 6px;
          .count {
            font-weight: 500;
            font-size: 14px;
            height: 18px;
            color: $primaryText;
          }

          .icon {
            font-size: 20px;
            color: $primaryDark;
          }
        }

        .toggle {
          height: 24px;
        }
      }
    }
  }
</style>
