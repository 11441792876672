<script lang="ts">
  export default {
    name: 'FontSelector',
  };
</script>

<script setup lang="ts">
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { OnClickOutside } from '@vueuse/components';
  import BaseDivider from '@ui/atoms/baseDivider/BaseDivider.vue';
  import { PropType, ref } from 'vue';
  import { RICH_TEXT } from '@/constant/richText';
  import { Font } from '@/components/common/fontSelector/types';

  const { fonts, value, disabled } = defineProps({
    fonts: { type: Array as PropType<Font[]>, required: true, default: [] as Font[] },
    value: { type: String, required: true, default: RICH_TEXT.DefaultFontFamily },
    disabled: { type: Boolean, default: false },
    relative: { type: Boolean, default: true },
  });

  const emit = defineEmits<{
    (event: 'update:value', value: string): void;
  }>();

  const defaultFonts = ref([RICH_TEXT.DefaultFontFamily]);
  const selectedFont = ref(value);
  const isActive = ref(false);

  function showFontFamily(active: boolean) {
    isActive.value = active;
  }

  function setFontFamily(value: string) {
    showFontFamily(false);
    selectedFont.value = value;

    emit('update:value', value);
  }
</script>

<template>
  <div class="select-container" :style="`position: ${relative ? 'relative' : 'auto'}`">
    <button
      class="select-button"
      ref="fontFamilyButton"
      @click="showFontFamily(!isActive)"
      :disabled="disabled"
    >
      <BaseText :style="{ fontFamily: value }">{{ value }}</BaseText>
      <i class="material-icons">keyboard_arrow_down</i>
    </button>
    <transition name="fade-top">
      <OnClickOutside
        v-if="isActive"
        class="select-list scrollbar"
        :options="{ ignore: [$refs.fontFamilyButton] }"
        @trigger="setFontFamily(value)"
      >
        <div class="fontfamily-section">
          <BaseText class="fontfamily-header" variant="caption1">Default</BaseText>
          <div
            class="select-list-item"
            v-for="v in defaultFonts"
            :key="v"
            @click="setFontFamily(v)"
          >
            <BaseText :style="{ fontFamily: v }">{{ v }}</BaseText>
          </div>
          <BaseDivider />
        </div>

        <div class="fontfamily-section">
          <BaseText class="fontfamily-header" variant="caption1">Font</BaseText>
          <div
            class="select-list-item"
            v-for="font in fonts"
            :key="font.id"
            @click="setFontFamily(font.name)"
          >
            <BaseText :style="{ fontFamily: font.name }">{{ font.name }}</BaseText>
          </div>
        </div>
      </OnClickOutside>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  .select-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0 -2px 0 2px;
    padding: 4px 8px;
    box-shadow: 0 0 0 1px $borderGrey;
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: white;
    transition: all 0.2s ease-in-out;

    > :first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      float: left;
      max-width: 85%;
    }

    > :last-child {
      float: right;
      max-width: 15%;
    }
  }

  .fontfamily-section {
    display: flex;
    flex-direction: column;
  }

  .fontfamily-header {
    display: flex;
    padding: 0 16px;
    margin-bottom: 4px;
    cursor: default;
    font-weight: bold;
  }

  .select-container {
    width: 100%;
    height: 38px;

    .fade-top-enter-active,
    .fade-top-leave-active {
      transition: all 0.3s ease;
    }

    .fade-top-enter {
      opacity: 0;
      transform: translateY(-20px);
    }

    .fade-top-leave-to {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  .select-list {
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 2px;
    padding: 8px 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .select-list-item {
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }
</style>
