<template>
  <div class="content-browser-modal modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <i
            v-if="currentFolderPath.length > 0"
            class="material-icons align-middle"
            @click="returnToPreviousFolder"
          >
            arrow_back
          </i>

          <h5 class="modal-title">{{ title || $lang.playlist.media_library }}</h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="close"
          >
            <i class="material-icons align-middle">close</i>
          </button>
        </div>

        <div class="modal-body scrollbar">
          <ContentBrowser
            ref="content_browser"
            @item-selected="handleItemSelect"
            @path-change="manageFolderChanged"
            :allow-multiple-item-select="allowMultipleItemSelect"
            :picker-modal="true"
            :filterItemTypes="filterItemTypes"
            :load-on-mount="false"
            hideBreadcrumbs
          />
        </div>

        <div class="modal-footer">
          <button type="button" class="button-2-secondary" @click.prevent="close">
            {{ $lang.common.cancel }}
          </button>

          <button v-on:click="submitSelected()" type="button" class="button-2-primary">
            <i v-if="isSaving" class="material-icons align-middle spin-animation">refresh</i>

            {{ $lang.common.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ContentBrowser from '@/components/content/ContentBrowser.vue';
  import { simpleTypeMixin } from '@/helpers';
  const $ = window.jQuery;

  export default {
    name: 'ContentBrowserModal',

    mixins: [simpleTypeMixin],

    components: {
      ContentBrowser,
    },

    props: {
      title: {
        type: String,
        default: '',
      },
      allowMultipleItemSelect: {
        type: Boolean,
        default: false,
      },

      filterItemTypes: {
        type: Array,
        default: () => [],
      },

      isSaving: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        selectedFiles: [],
        currentFolderPath: [],
      };
    },

    methods: {
      manageFolderChanged(newFolderPath) {
        this.currentFolderPath = !!newFolderPath ? newFolderPath.split('/') : [];
      },

      handleItemSelect(selectedFiles) {
        this.selectedFiles = selectedFiles;
      },

      submitSelected() {
        $(this.$el).modal('hide');
        return this.$emit('submitted', this.selectedFiles);
      },

      open() {
        this.$refs.content_browser.reloadContent();
        $(this.$el).modal('show');
      },

      close() {
        this.selectedFiles = [];
        this.$refs.content_browser.deSelectAll();
        this.$emit('closing_modal');
        $(this.$el).modal('hide');
      },

      returnToPreviousFolder() {
        if (this.currentFolderPath.length === 1) {
          this.$refs.content_browser.showRootContentPage();
        } else if (this.currentFolderPath.length > 1) {
          this.$refs.content_browser.onDirectoryTreeClicked(this.currentFolderPath.length - 2);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-dialog {
    min-width: min(90vw, 742px);
    z-index: 2000;

    .modal-header {
      display: flex;
      align-items: center;
      background-color: #fff;
      color: $primaryText;
      font-size: 20px;
      gap: 20px;
      padding: 24px 0 16px;
      margin: 0 32px;
      border-bottom: 2px solid $lineGrey;
      line-height: 32px;
      font-weight: bold;

      h5 {
        font-family: inherit;
      }

      & > i {
        cursor: pointer;
      }
    }

    .modal-footer {
      justify-content: flex-end;
      display: flex;
      gap: 16px;
      padding: 24px 32px;
    }

    .modal-content {
      border-radius: 0;
      padding: 0;
      border: none;
      min-height: min(640px, 80vh);

      .modal-body {
        margin-top: 16px;
        padding: 0 10px 16px 10px;
        max-height: calc(474px - 166px);
        min-height: min(474px, calc(80vh - 180px));
        min-width: 80vw;
        overflow-y: auto;
        padding: 16px 32px;
      }
    }
  }
</style>
