<template>
  <Modal
    v-if="showModal"
    class="modal"
    id="content-options-modal"
    :title="previewItem?.name"
    :modalStyles="{ width: '90vw' }"
    @closeModal="closeModal"
  >
    <template v-slot:header><div></div></template>

    <template v-slot:body>
      <div class="modal-body scrollbar">
        <div class="preview-container" :style="contentStyle">
          <PlaylistSlide
            :slide="{ ...previewItem, imageMode: 'contain' }"
            :playlist="playlist"
            show
          />

          <i class="material-icons-outlined button close-button" @click="closeModal">close</i>
        </div>
      </div>
    </template>

    <template v-slot:footer><div></div></template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  import { ALLOW_PLAYER_MODAL } from '@/store/actions/player';
  import { SET_IS_PLAYLIST_PREVIEW_ON } from '@/store/actions/playlist';
  import { SET_PREVIEW } from '@/store/actions/preview';

  export default {
    name: 'PlaylistItemModal',

    components: { Modal, PlaylistSlide: () => import('@/components/player/PlaylistSlide.vue') },

    props: {
      previewItem: {
        type: Object,
        default: () => null,
      },
      playlist: {
        type: Object,
        default: () => null,
      },
    },

    data() {
      return {
        showModal: false,
      };
    },

    mounted() {
      this.showModal = true;
      this.$store.commit(SET_IS_PLAYLIST_PREVIEW_ON, true);
      this.$store.dispatch(SET_PREVIEW, true);
      this.$store.commit(ALLOW_PLAYER_MODAL, true);
    },

    beforeDestroy() {
      this.$store.commit(SET_IS_PLAYLIST_PREVIEW_ON, false);
      this.$store.dispatch(SET_PREVIEW, false);
    },

    computed: {
      contentStyle() {
        const templateData = this.previewItem?.template?.settings;

        return {
          width: templateData ? `${templateData.width}px` : '100%',
          height: templateData ? `${templateData.height}px` : '100%',
        };
      },
    },

    methods: {
      closeModal() {
        this.$store.commit(ALLOW_PLAYER_MODAL, false);
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    text-align: inherit !important;

    .modal-container {
      width: 90vw;
    }

    .modal-body {
      height: calc(90vh - 32px);
      padding: 0;
    }
  }

  .modal {
    .modal-header {
      font-size: 20px;
      line-height: 32px;
      background: none !important;
      border: none !important;

      position: relative;
      padding: 27px 0 19px;
      margin: 0 32px;
      color: $primaryText;

      @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
      }
    }

    .modal-body {
      display: flex;
      overflow-y: auto;

      .preview-container {
        margin: auto;

        .playlist-slide {
          position: relative;
        }
      }
    }
  }

  .close-button {
    color: $iconsBlack;
    position: absolute;
    padding: 5px;
    top: 15px;
    right: 10px;
    font-size: 20px;

    &:hover {
      color: $primaryRed;
    }
  }

  ::v-deep .preview-container {
    .pdf-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
</style>
