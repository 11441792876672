export enum FileUploadStatus {
  Uploading,
  PendingProcess,
  Processing,
  Completed,
  Failed,
}

export interface FileUploadOption {
  file: File;
  status: FileUploadStatus | null;
  percent: number;
}

export interface Folder {
  id: number;
  name: string;
}
