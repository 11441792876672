<template>
  <div>
    <i v-if="itemType.includes(WIDGET_TYPES.IMAGE)" class="material-icons-outlined align-middle">
      crop_original
    </i>

    <i v-else-if="itemType.includes(WIDGET_TYPES.PDF)" class="material-icons-outlined align-middle">
      picture_as_pdf
    </i>

    <i
      v-else-if="itemType.includes(WIDGET_TYPES.LAYER)"
      class="material-icons-outlined align-middle"
    >
      layers
    </i>

    <i v-else-if="itemType.includes(APP_TYPES.TABLE)" class="material-icons-outlined align-middle">
      table_rows
    </i>

    <i
      v-else-if="itemType.includes(APP_TYPES.WEATHER)"
      class="material-icons-outlined align-middle"
    >
      cloud
    </i>

    <i v-else-if="itemType.includes(APP_TYPES.RSS)" class="material-icons-outlined align-middle">
      rss_feed
    </i>

    <i
      v-else-if="itemType.includes(APP_TYPES.TRAFIKLAB)"
      class="material-icons-outlined align-middle"
    >
      widgets
    </i>

    <i
      v-else-if="itemType.includes(WIDGET_TYPES.TILE)"
      class="material-icons-outlined align-middle"
    >
      now_wallpaper
    </i>

    <i
      v-else-if="itemType.includes(WIDGET_TYPES.TEMPLATE)"
      class="material-icons-outlined align-middle"
    >
      photo_size_select_large
    </i>

    <i
      v-else-if="itemType.includes(WIDGET_TYPES.VIDEO) || itemType === 'youtube'"
      class="material-icons-outlined align-middle"
    >
      play_circle_outline
    </i>
    <i
      v-else-if="itemType.includes(WIDGET_TYPES.AUDIO)"
      class="material-symbols-outlined align-middle"
    >
      audio_file
    </i>
  </div>
</template>

<script>
  import { APP_TYPES, WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    name: 'PlaylistItemIcon',

    props: {
      itemType: {
        type: String,
        default: 'Unknown',
      },
    },

    data() {
      return {
        WIDGET_TYPES,
        APP_TYPES,
      };
    },
  };
</script>

<style></style>
