<template>
  <SidebarContainer class="screens-sidebar-container">
    <template v-if="isLoading"> <Loader /> </template>
    <template v-else>
      <div class="sidebar-body-inner">
        <OrientationTabs
          @update:activeTab="onChangeTab"
          :active-tab="activeOrientation"
          :horizontal-layouts-count="layouts.filter((layout) => isLayoutHorizontal(layout)).length"
          :vertical-layouts-count="layouts.filter((layout) => !isLayoutHorizontal(layout)).length"
        />
        <template v-if="!layout">
          <LayoutsList
            :showSearch="true"
            :layouts="filteredLayouts"
            @select="handleLayoutSelect"
            :empty-text="`No ${activeOrientation} layouts`"
          />
          <!-- TODO: Decide how to manage permissions -->

          <!-- <Button
            theme="secondary"
            icon="edit_attributes"
            class="full"
            @click="openPermissionsModal"
          >
            Manage Permissions
          </Button> -->
        </template>
        <template v-else>
          <div class="sidebar-body-inner">
            <div v-if="!!layout" class="return-group" @click="layout = null">
              <i class="material-icons-outlined align-middle button">arrow_back</i>

              <div class="return-text">
                Previewing <span>{{ layout.settings.name }}</span>
              </div>
            </div>
          </div>

          <div class="preview-container">
            <div class="preview-content" :style="{ aspectRatio: previewAspectRatio }">
              <Player v-if="!showPreviewModal" :layout_id="layout.layout_id" :key="key" isPreview />
            </div>

            <div class="preview-footer">
              <ConnectionLights
                :connected="connectedScreensWithCurrentLayout.length"
                :unknown="unknownScreensWithCurrentLayout.length"
                :disconnected="disconnectedScreensWithCurrentLayout.length"
                :size="12"
              />
              <div class="right">
                <RotateScreenButton
                  title="Rotate Screens"
                  :isRotating="isRotating"
                  @rotateScreens="
                    (orientation = 'portrait') =>
                      rotateScreens(groupScreensWithCurrentLayout, orientation)
                  "
                />

                <div v-if="isRefreshing">
                  <Loader :size="20" />
                </div>
                <i
                  v-else
                  class="material-icons align-middle button"
                  title="Restart Screen"
                  @click="refreshScreens(groupScreensWithCurrentLayout)"
                >
                  refresh
                </i>
                <i
                  class="material-icons-outlined align-middle button"
                  title="Open Full Screen"
                  @click="openPreviewOverlay"
                >
                  fullscreen
                </i>
              </div>
            </div>
          </div>

          <LayoutsPreviewModal
            v-if="showPreviewModal"
            :layoutId="layout.layout_id"
            @closeModal="handleClosePreview"
          />
        </template>
      </div>

      <!-- Permissions Modal -->
      <!-- TODO: Decide how to manage permissions -->
    </template>
    <template #buttons v-if="!isLoading">
      <Button theme="primary" icon="add_to_queue" class="full" @click="openScreenModal">
        Add Screen
      </Button>
      <Button theme="primary" icon="add" class="full" @click="openGroupModal">
        Create Group
      </Button>
    </template>
  </SidebarContainer>
</template>

<script>
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import OrientationTabs from '@/components/common/OrientationTabs/OrientationTabs.vue';
  import Button from '@/components/common/Button.vue';
  import LayoutsList from './LayoutsList.vue';
  import Loader from '@/components/common/Loader.vue';
  import Player from '@/components/player/Index.vue';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';
  import RotateScreenButton from '../common/screens/RotateScreenButton.vue';
  import LayoutsPreviewModal from '@/components/layouts/LayoutsPreviewModal.vue';

  import { apiUpdateLayout } from '@/api/layouts';
  import { rotateScreen, apiGetScreenGroupLayouts } from '@/api/screens';

  import { SCREEN_LAYOUT_FETCH, SCREENS_RESTART } from '@/store/actions/screens';
  import { PublicationType } from '@/types/api/screens';
  import { SCREEN_GROUP_TYPE } from '@/constant/screens';

  export default {
    name: 'ScreensSidebar',

    inject: ['getGroupScreens', 'openScreenModal', 'openGroupModal', 'reloadScreensData'],

    emits: ['open-screen-modal', 'open-group-modal'],

    data() {
      return {
        isLoading: false,
        isRotating: false,
        isRefreshing: false,
        showPreviewModal: false,
        layouts: [],
        activeOrientation: 'vertical',
        layout: null,
        key: 1,
      };
    },

    components: {
      SidebarContainer,
      OrientationTabs,
      LayoutsList,
      Button,
      Loader,
      Player,
      ConnectionLights,
      LayoutsPreviewModal,
      RotateScreenButton,
    },

    computed: {
      groupScreens() {
        return this.getGroupScreens();
      },

      filteredLayouts() {
        return this.layouts.filter((layout) =>
          this.activeOrientation === 'horizontal'
            ? layout?.settings?.isHorizontal
            : !layout?.settings?.isHorizontal,
        );
      },

      groupScreensWithCurrentLayout() {
        return this.groupScreens.filter((screen) => screen.layout === this.layout?.layout_id);
      },

      connectedScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) => screen?.screen_information?.connection_status === true,
        );
      },

      disconnectedScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) => screen?.screen_information?.connection_status === false,
        );
      },

      unknownScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) => !screen?.screen_information?.connection_status,
        );
      },

      previewAspectRatio() {
        if (!this.layout) return;
        let layout = this.layout;
        let originalRatio = layout.settings.aspectRatio;

        if (!layout.settings.isHorizontal) return originalRatio.split(':').reverse().join('/');

        return originalRatio.replace(':', '/');
      },
    },

    methods: {
      onChangeTab(tab) {
        this.layout = null;
        this.activeOrientation = tab;
      },

      async fetchData(silent = false) {
        this.isLoading = !silent;
        await this.fetchLayoutData();
        this.isLoading = false;
      },

      async fetchLayoutData() {
        if (this.groupScreens.length) {
          const screenGroupId = this.groupScreens[0].screen_group ?? SCREEN_GROUP_TYPE.UNGROUPED;
          this.layouts = await apiGetScreenGroupLayouts(screenGroupId);
        }
      },

      isLayoutHorizontal(layout) {
        return layout.settings.isHorizontal;
      },

      handleLayoutSelect(layoutId) {
        this.layout = this.layouts.find((l) => l.layout_id === layoutId);
      },

      async rotateScreens(screens, orientation) {
        this.isRotating = true;
        const screensList = screens || this.groupScreens;

        try {
          const promises = screensList.map(async (screen) => {
            const layout = this.layouts.find((l) => l.layout_id === screen.layout);

            if (!layout) return;

            await rotateScreen(screen.id, orientation);

            await apiUpdateLayout(layout.layout_id, {
              ...layout.settings,
              isHorizontal: ['landscape', '180d'].includes(orientation),
            });
          });

          await Promise.all(promises);
          await this.fetchData(true);
          await this.reloadScreensData(true);
          this.$toasted.global.general_success({
            message: `Screens rotated successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to rotate screen' });
        }
        this.isRotating = false;
      },

      async refreshScreens(screens) {
        this.isRefreshing = true;
        screens = !!screens ? screens : this.groupScreens;
        try {
          await this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenPublication,
            data: { screen_ids: screens.map((screen) => screen.id) },
          });
          await this.fetchData(true);
          this.$toasted.global.general_success({
            message: `Screens refreshed successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to refresh screens' });
        }
        this.isRefreshing = false;
      },

      openPreviewOverlay() {
        this.showPreviewModal = true;
      },

      handleClosePreview() {
        this.showPreviewModal = false;
      },
    },
    created() {
      this.fetchData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .overlay-modal {
    .ui-modal-content {
      aspect-ratio: 16/9;
      width: auto !important;
      padding: 10px !important;
      border-radius: 4px;
      background-color: #fafafa !important;

      .web-player-v2 {
        height: 100%;
        border: 2px solid $primaryRed;
      }
    }
  }
  .screens-sidebar-container {
    padding-top: 50px;

    .sidebar-body-inner {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .return-group {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $secondaryText;
        gap: 16px;
        cursor: pointer;

        span {
          color: $primaryText;
          font-weight: 700;
        }

        &:hover {
          i {
            color: $primaryRed;
          }
        }
      }
    }

    .preview-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .preview-content {
        min-height: 205px;
        background-color: $fillGrey;

        ::v-deep .web-player-v2 {
          height: 100%;
        }
      }

      .preview-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;

        .right {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
</style>
