var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-grid"},[_c('div',{staticClass:"card-decks"},_vm._l((_vm.items),function(item,cardIndex){return _c('div',{key:cardIndex,staticClass:"card-column"},[(item && item.content_type !== _vm.CONTENT_TYPE_FOLDER)?_c('div',{staticClass:"card content-item",class:{
          active: _vm.isActiveItem(cardIndex) || _vm.isActiveTemplate(cardIndex, item),
          disabled: item.hasOwnProperty('selectable') && !item.selectable,
        },style:(_vm.style),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onItemClicked($event, cardIndex)}}},[_c('div',{staticClass:"hovereffect"},[(['image', 'rss', 'table', 'trafiklab', 'weather'].includes(_vm.getType(item)))?_c('div',{staticClass:"thumbnail",class:{
              'trafiklab-bg': item.item_type === 'app/trafiklab',
            },style:(_vm.getThumbnailStyle(item))}):(_vm.getType(item) === 'pdf')?_c('div',{staticClass:"thumbnail",style:(_vm.getThumbnailStyle(item))},[_vm._m(0,true)]):_vm._e(),(_vm.getType(item) === 'video')?_c('div',{staticClass:"thumbnail item-preview-video",style:(_vm.getVideoStyle(item))},[_vm._m(1,true)]):(_vm.getType(item) === 'audio')?_c('div',{staticClass:"thumbnail",style:(_vm.getThumbnailStyle(item))},[_vm._m(2,true)]):_vm._e(),(_vm.isTemplate(item))?_c('div',{staticClass:"template-thumbnail"},[_c('img',{attrs:{"src":item.img,"alt":item.name}})]):_vm._e()]),_c('div',{staticClass:"card-body file"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-description"},[_vm._v(" "+_vm._s(_vm.getDescription(item))+" ")])])]):_vm._e(),(item && item.content_type === _vm.CONTENT_TYPE_FOLDER)?_c('div',{staticClass:"card",class:_vm.getItemClass(cardIndex),style:(_vm.style),on:{"click":function($event){$event.preventDefault();return _vm.onItemClicked($event, cardIndex)}}},[_c('img',{attrs:{"src":require("../../assets/img/content/folder.svg"),"alt":`folder ${item.name}`}}),_c('div',{staticClass:"card-body folder"},[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e()])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-img-overlay d-flex align-items-center justify-content-center"},[_c('i',{staticClass:"material-icons play-icon"},[_vm._v("picture_as_pdf")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-img-overlay d-flex align-items-center justify-content-center"},[_c('i',{staticClass:"material-icons play-icon"},[_vm._v("play_circle_outline")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-img-overlay d-flex align-items-center justify-content-center"},[_c('i',{staticClass:"material-symbols-outlined play-icon"},[_vm._v("audio_file")])])
}]

export { render, staticRenderFns }