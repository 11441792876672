<script lang="ts">
  export default {
    name: 'BaseSlider',
  };
</script>

<script setup lang="ts">
  import { computed, toRefs } from 'vue';
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/default.css';

  const props = withDefaults(
    defineProps<{
      value?: number;
      interval?: number;
      min?: number;
      max?: number;
      disabled?: boolean;
    }>(),
    {
      value: 100,
      interval: 1,
      min: 0,
      max: 100,
      disabled: false,
    },
  );
  const { value, interval, min, max, disabled } = toRefs(props);

  const emit = defineEmits<{ (event: 'update:value', value: number): void }>();

  const sliderStyle = computed(() => {
    return {
      baseLine: { backgroundColor: '#F0F0F0', borderRadius: 0, marginLeft: '6px' },
      valueLine: { backgroundColor: '#E3003A', borderRadius: 0 },
      dot: {
        backgroundColor: '#E3003A',
        border: '2px solid white',
        boxShadow: 'none',
        marginTop: '-4px',
        height: '22px',
        width: '22px',
      },
    };
  });

  function handleChange(value: number) {
    emit('update:value', value);
  }
</script>

<template>
  <div class="base-slider">
    <VueSlider
      :disabled="disabled"
      :value="value"
      @change="handleChange"
      :dotStyle="sliderStyle.dot"
      :railStyle="sliderStyle.baseLine"
      :processStyle="sliderStyle.valueLine"
      :interval="interval"
      :min="min"
      :max="max"
      tooltip="none"
      useKeyboard
    />
  </div>
</template>

<style scoped lang="scss">
  .base-slider {
    display: block;
  }
</style>
