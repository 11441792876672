<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'AudioView',
  });
</script>

<script setup lang="ts">
  import { toRefs } from 'vue';

  const props = withDefaults(
    defineProps<{
      src: string;
      type: string;
    }>(),
    {},
  );
  const { src, type } = toRefs(props);
</script>

<template>
  <div class="container">
    <div class="cover-section">
      <i class="material-symbols-outlined icon">audio_file</i>
    </div>
    <audio controls>
      <source :src="src" :type="type" />
      Your browser does not support the audio extension.
    </audio>
  </div>
</template>

<style scoped lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  .cover-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .icon {
    font-size: 48px;
  }
</style>
