<template>
  <Modal @closeModal="closeModal" v-show="showModal" title="Organisation's Fonts">
    <template v-slot:body>
      <form
        id="uploadFontForm"
        enctype="multipart/form-data"
        ref="fontUploader"
        @submit.prevent="uploadFont"
      >
        <div class="modal-body">
          <div class="description">Upload a font you want to use:</div>
          <div class="form-group form-label-group">
            <div class="subtitle">
              Only <strong>woff</strong> and <strong>woff2</strong> fonts are allowed.
            </div>
            <div class="custom-file">
              <input
                @change="setFont"
                ref="uploadFonts"
                type="file"
                class="custom-file-input"
                id="uploadFont"
                accept=".woff, .woff2, .otf, .ttf"
                lang="us"
              />
              <label class="custom-file-label" for="uploadFont">
                {{ uploadedFont ? uploadedFont.name : $lang.user_settings.select_files }}
              </label>
            </div>
          </div>

          <div v-if="userFonts.length > 0 || searchedFont !== ''">
            <div class="description">Uploaded Fonts:</div>
            <input
              type="search"
              class="form-control my-3"
              placeholder="Search by font name"
              v-model="searchedFont"
            />
            <ul class="list-group overflow-auto mb-2 scrollbar" style="max-height: 400px">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
                v-for="font in userFonts"
                :key="font.id"
              >
                <span style="font-size: 16px">{{ font.name }}</span>
                <i
                  v-if="!font.is_predefined && isAdmin"
                  class="material-icons align-middle cursor-pointer ml-3 text-danger"
                  @click="deleteFont(font)"
                  >delete</i
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="modal-footer">
          <div class="modal-buttons justify-content-end" role="group" aria-label="Form controls">
            <button
              type="button"
              class="btn btn-transparent-grey"
              data-dismiss="modal"
              @click="closeModal"
            >
              {{ $lang.common.close }}
            </button>

            <SendButton
              v-if="uploadedFont"
              type="submit"
              :disabled="isLoading"
              :isLoading="isLoading"
              title="Upload Font"
              class="ml-3"
            />
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer><div></div> </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';
  import SendButton from '@/components/common/SendButton.vue';
  import { DELETE_USER_FONT, UPLOAD_USER_FONT } from '@/store/actions/fonts';
  import { ALLOWED_UPLOAD_FONTS } from '@/constant/const';

  export default {
    name: 'FontsModal',

    components: { Modal, SendButton },

    data() {
      return {
        showModal: false,
        isLoading: false,
        uploadedFont: null,
        searchedFont: '',
      };
    },

    mounted() {
      this.showModal = true;
    },

    computed: {
      userFonts() {
        return this.$store.getters.userFonts.filter((font) =>
          font.name.toLowerCase().includes(this.searchedFont.toLowerCase()),
        );
      },
      isAdmin() {
        return this.$store.getters.isOrganizationAdmin || this.$store.getters.isAdministrator;
      },
    },

    methods: {
      setFont(event) {
        this.uploadedFont = null;

        try {
          const uploadedFile = event.target.files[0];
          const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

          if (!ALLOWED_UPLOAD_FONTS.includes(fileExtension)) {
            this.$toasted.global.general_error({
              message: 'File extension not allowed',
            });

            return;
          }

          this.uploadedFont = uploadedFile;
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Error uploading your font. Please try again later.',
          });
        }
      },

      async uploadFont(data) {
        this.isLoading = true;

        try {
          const formData = new FormData();
          formData.append('upload', this.uploadedFont);
          formData.append('font_name', this.uploadedFont.name.split('.')[0]);

          await this.$store.dispatch(UPLOAD_USER_FONT, formData);
          this.uploadedFont = null;
          this.$refs.uploadFonts.value = null;
          this.$toasted.global.general_success({
            message: 'Font Uploaded',
          });
        } catch (e) {
          this.$toasted.global.general_error({
            message:
              e.response.data.message || 'Ups, something went wrong, please try again later.',
          });
        }

        this.isLoading = false;
      },

      async deleteFont(font) {
        this.isLoading = true;

        try {
          const successful = await this.$store.dispatch(DELETE_USER_FONT, font.id);

          if (successful) {
            this.$toasted.global.general_success({
              message: 'Font deleted',
            });
          } else {
            throw new Error('Failed');
          }
        } catch (e) {
          console.error(e);
          this.$toasted.global.general_error({
            message: 'Ups, something went wrong, please try again later.',
          });
        }

        this.isLoading = false;
      },

      closeModal() {
        if (this.isLoading) return;

        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 500px;
    padding: 32px;
    padding-bottom: 12px;
    .description {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .subtitle {
      font-size: 14px;
      color: #525252;
    }
  }

  .layers {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    min-height: 45px;

    .layer {
      position: relative;
      background-color: $lightblue;
      padding: 10px 26px;
      border-radius: 8px;
      color: white;
      font-weight: 500;
      max-width: 180px;
      height: fit-content;
      word-break: break-all;

      .close-button {
        color: black;
        position: absolute;
        top: 5px;
        right: 6px;
        font-size: 16px;

        &:hover {
          color: white;
        }
      }
    }
  }

  .modal-footer {
    padding: 20px;
    border: none;
    .modal-buttons {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    button {
      min-width: 120px;
    }
  }
</style>
