import config from '@/config';
import axios from 'axios';
import { Address, DetailedAddress } from '@/types/api/tenants';
import { stringify } from 'qs';

export async function apiGetAddresses(search: string | null = null): Promise<Address[]> {
  const response = await axios.get<Address[]>(
    `${config.apiUrl}userapi/integrations/tenant-integration/addresses`,
    { params: { search: search } },
  );

  return response.data;
}

export async function apiGetDetailedAddress(
  externalId: string,
  floors?: number[],
  sortByFloorOrder: boolean = true,
): Promise<DetailedAddress> {
  const response = await axios.get<DetailedAddress>(
    `${config.apiUrl}userapi/integrations/tenant-integration/detailed-address`,
    {
      params: {
        externalId,
        floors,
        sortByFloorOrder: sortByFloorOrder,
      },
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );

  return response.data;
}
