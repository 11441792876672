<script lang="ts">
  export default {
    name: 'PlaylistItemAudioModal',
  };
</script>

<script setup lang="ts">
  import { ref, toRefs } from 'vue';
  import Modal from '@/components/common/Modal.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { BaseSlider } from '@ui/atoms/baseSlider';

  const props = withDefaults(
    defineProps<{
      playlistItem: any;
    }>(),
    {},
  );
  const { playlistItem } = toRefs(props);

  const emit = defineEmits<{
    (event: 'close'): void;
    (
      event: 'save',
      data: { wid: string; assocId: number; overrideId?: number; mute: boolean; volume: number },
    ): void;
  }>();

  const isMuted = ref<boolean>(playlistItem.value?.mute ? true : false);
  const volumeValue = ref<number>(
    playlistItem.value?.volume ? Math.round(playlistItem.value?.volume * 100) : 100,
  );

  function close() {
    emit('close');
  }

  function save() {
    emit('save', {
      wid: playlistItem.value.wid,
      assocId: playlistItem.value.assoc_id,
      overrideId: playlistItem.value.override?.id,
      mute: isMuted.value,
      volume: volumeValue.value / 100,
    });

    close();
  }

  function handleVolume(value: number) {
    volumeValue.value = value;
  }
</script>

<template>
  <Modal
    @closeModal="close"
    :title="'Item Audio'"
    :okFunction="save"
    okButtonLabel="Save"
    :modalStyles="{ width: '400px' }"
  >
    <template v-slot:body>
      <div class="container">
        <div class="row">
          <BaseText variant="subtitle2" class="styled-text">Volume</BaseText>
          <BaseButton
            theme="secondary"
            class="mute-button"
            :title="isMuted ? 'Unmute' : 'Mute'"
            @click="isMuted = !isMuted"
          >
            <i v-if="isMuted" class="material-symbols-outlined">volume_off</i>
            <i v-else class="material-symbols-outlined">volume_up</i>
          </BaseButton>
        </div>
        <div class="row">
          <div class="slider-container">
            <BaseSlider :value="volumeValue" @update:value="handleVolume" />
          </div>
          <div class="text-volume-box">
            <BaseText variant="subtitle2" class="styled-text">{{ volumeValue }}</BaseText>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
  .styled-text {
    font-size: 14px;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    width: 100%;
    height: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    height: 40px;
    width: auto;
  }

  .mute-button {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: $lineGrey;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;

    i {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .slider-container {
    width: 276px;
  }

  .text-volume-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
</style>
