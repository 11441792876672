<template>
  <transition name="modal">
    <div class="modal-mask py-5" @click="handleClickOutside">
      <div class="modal-wrapper">
        <div class="modal-container" :style="modalStyles" @click.stop>
          <slot name="header">
            <div class="modal-header">
              <i
                v-show="!noCancelButton"
                class="material-icons-outlined button close-button"
                @click="!!cancelFunction ? cancelFunction() : closeModal()"
              >
                close
              </i>

              <div class="modal-title">{{ title }}</div>
            </div>
          </slot>

          <div class="modal-body" :style="{ overflowY: noScrollbar ? 'visible' : 'auto' }">
            <slot name="body"> </slot>
          </div>

          <slot name="footer">
            <div class="modal-footer" role="group" aria-label="Form controls">
              <button
                v-if="thirdFunction && thirdButtonLabel"
                type="button"
                class="button-2-secondary"
                @click="!!thirdFunction ? thirdFunction() : closeModal()"
              >
                {{ thirdButtonLabel }}
              </button>

              <button
                v-show="!noCancelButton"
                type="button"
                class="button-2-secondary"
                @click="!!cancelFunction ? cancelFunction() : closeModal()"
              >
                {{ cancelButtonLabel }}
              </button>

              <SendButton
                :type="submitForm ? 'text' : 'submit'"
                :disabled="disabled || isSaving || isLoading"
                :isLoading="isSaving"
                :title="okButtonLabel"
                :class="`${okButtonClasses}`"
                @click="okFunction()"
              >
                {{ okButtonLabel }}
              </SendButton>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import SendButton from '@/components/common/SendButton.vue';

  export default {
    name: 'Modal',

    components: { SendButton },

    props: {
      title: {
        type: String,
        default: '',
      },
      okButtonLabel: {
        type: String,
        default() {
          return this.$lang.common.accept;
        },
      },
      okFunction: {
        type: Function,
        default: () => {},
      },
      cancelFunction: {
        type: Function,
        default: null,
      },
      cancelButtonLabel: {
        type: String,
        default() {
          return this.$lang.common.cancel;
        },
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      noCancelButton: {
        type: Boolean,
        default: false,
      },
      noScrollbar: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      avoidClickOutside: {
        type: Boolean,
        default: false,
      },
      okButtonClasses: {
        type: String,
        default: '',
      },
      modalStyles: {
        type: Object,
        default: () => ({}),
      },
      submitForm: {
        type: Boolean,
        default: false,
      },
      thirdFunction: {
        type: Function,
        default: null,
      },
      thirdButtonLabel: {
        type: String,
        default: '',
      },
    },

    methods: {
      closeModal() {
        this.$emit('closeModal');
      },

      handleClickOutside() {
        if (!this.avoidClickOutside) {
          this.$emit('closeModal');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-wrapper {
    cursor: auto;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: calc(100vw - 2rem);
    max-height: 95vh;
    padding: 0;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.8s ease;

    .close-button {
      color: $iconsBlack;
      position: absolute;
      padding: 5px;
      right: 5px;
      font-size: 20px;

      &:hover {
        color: $primaryRed;
      }
    }

    .modal-header {
      position: relative;
      padding: 27px 0 19px;
      margin: 0 32px;
      font-size: 20px;
      line-height: 32px;
      color: $primaryText;
      border-bottom: 2px solid $lineGrey;

      @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  .modal-body {
    min-height: 60px;
    margin: 0 0 0 32px;
    padding: 16px 32px 32px 0;
    overflow-y: auto;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px;
    gap: 16px;
    background-color: $backgroundGrey3;
    border: none;

    button {
      min-width: 114px;
      padding-top: 8px;
      padding-bottom: 8px;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  /* Transitions */
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
</style>
