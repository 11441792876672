<script lang="ts">
  export default {
    name: 'AudioSection',
  };
</script>

<script setup lang="ts">
  import { ref, toRefs } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { BaseSlider } from '@ui/atoms/baseSlider';

  const props = withDefaults(
    defineProps<{
      mute: boolean;
      volume: number;
    }>(),
    {},
  );

  const { mute, volume } = toRefs(props);

  const emit = defineEmits<{
    (event: 'change', data: { mute: boolean; volume: number }): void;
  }>();

  const isMuted = ref(mute.value);
  const volumeValue = ref(Math.round(volume.value * 100));

  function handleMuted(mute: boolean) {
    isMuted.value = mute;
    emit('change', { mute: mute, volume: volumeValue.value / 100 });
  }

  function handleVolume(value: number) {
    volumeValue.value = value;
    emit('change', { mute: isMuted.value, volume: value / 100 });
  }
</script>

<template>
  <div class="container">
    <div class="row">
      <BaseText variant="subtitle2" class="styled-text">Volume</BaseText>
      <BaseButton
        theme="secondary"
        class="mute-button"
        :title="isMuted ? 'Unmute' : 'Mute'"
        @click="handleMuted(!isMuted)"
      >
        <i v-if="isMuted" class="material-symbols-outlined">volume_off</i>
        <i v-else class="material-symbols-outlined">volume_up</i>
      </BaseButton>
    </div>
    <div class="row">
      <BaseSlider :value="volumeValue" @update:value="handleVolume" />
      <div class="text-volume-box">
        <BaseText variant="subtitle2" class="styled-text">{{ volumeValue }}</BaseText>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .styled-text {
    font-size: 14px;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 4px;
    width: 100%;
    height: 100%;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 40px;
    justify-content: space-between;
    column-gap: 16px;
    align-items: center;
    height: 40px;
    width: auto;
  }

  .mute-button {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: $lineGrey;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;

    i {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .text-volume-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
</style>
