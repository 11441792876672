import moment from 'moment';
import config from '../config';

export function formatSeconds(seconds) {
  const pad = (num) => String(num).padStart(2, '0');

  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

export const humanFileSize = (bytes, si = true) => {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export const shortenKey = (value) => {
  if (!value) return '';
  let tmpArray = value.split('/');
  tmpArray.shift();
  return tmpArray.join('/');
};

export const globalFilters = [
  {
    name: 'formatNumber',
    filter: (number) => Intl.NumberFormat().format(number),
  },
  {
    name: 'shortenKey',
    filter: shortenKey,
  },
  {
    name: 'alwaysShowStr',
    filter: (value) => {
      return value ? value : '[UNKNOWN]';
    },
  },
  {
    name: 'capitalize',
    filter: (value) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  {
    name: 'formatBytes',
    filter: (value, formatSi = true) => {
      return humanFileSize(value, formatSi);
    },
  },
  {
    name: 'formatDate',
    filter: (value, formatMethod, format = null) => {
      const _locale = config.locale === 'sw' ? 'sv' : config.locale;
      moment.locale(_locale);

      // Check if it's a Date object and convert to isoformat string
      if (typeof value.getMonth === 'function') {
        value = moment(value).format();
      }

      const momentDate = moment(String(value));

      var formatted_date = '';
      var format_options = [
        {
          method: 'fromNow',
          date: moment(String(value)).fromNow(),
        },
        {
          method: 'full',
          date: format ? moment(String(value)).format(format) : moment(String(value)).format(),
        },
        {
          method: 'date',
          date: `${momentDate.format('YYYY.MM.DD')} at ${momentDate.format('HH:mm')}`,
        },
      ];

      if (!value) return formatted_date;
      format_options.forEach((obj) => {
        if (formatMethod == obj.method) {
          formatted_date = obj.date;
        }
      });

      return formatted_date;
    },
  },
];
