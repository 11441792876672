<template>
  <div
    v-if="!!selectedWidget"
    v-show="!isWidgetMoving && show"
    class="designer-toolbar"
    :style="style"
  >
    <div class="toolbar-group">
      <div v-show="isWidgetLockedByOverride" class="styled-button">
        <i class="material-icons-outlined button lock"> lock </i>
        Overridden by group
      </div>
      <div v-show="!isWidgetLockedByOverride" class="buttons-container">
        <div class="styled-button" title="Move Up" @click="!isMoveUpDisabled && changeZIndex(1)">
          <i class="material-icons" :class="{ disabled: isMoveUpDisabled }"> keyboard_arrow_up </i>
        </div>
        <div
          class="styled-button"
          title="Move Down"
          @click="!isMoveDownDisabled && changeZIndex(-1)"
        >
          <i class="material-icons" :class="{ disabled: isMoveDownDisabled }">
            keyboard_arrow_down
          </i>
        </div>
        <div class="styled-button" @click="deleteWidget" title="Delete">
          <i class="material-icons-outlined"> delete </i>
        </div>
        <div
          class="styled-button"
          @click="updateLock"
          :title="isWidgetLocked ? 'Unlock position' : 'Lock position'"
        >
          <i class="material-icons-outlined" :class="isWidgetLocked ? 'unlock' : 'lock'">
            {{ isWidgetLocked ? 'lock_open' : 'lock' }}
          </i>
        </div>
        <div
          v-show="isDuplicateSupported"
          class="styled-button"
          title="Duplicate"
          @click="() => duplicateWidget()"
        >
          <i class="material-icons-outlined">content_copy</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { REFRESH_TEMPLATE_STATUS } from '@/config/constants';
  import {
    TEMPLATE_DESIGNER_DUPLICATE_WIDGET,
    SET_SAVE_Z_INDEXES,
    TEMPLATE_DESIGNER_SET_ZINDEX,
    UPDATE_TEMPLATE_WIDGET_STATE,
    TEMPLATE_DESIGNER_SELECT_WIDGET,
  } from '@/store/actions/templateDesigner';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import { duplicateTemplateTextWidget } from '@/helpers/widgets/textWidget/textWidget';

  export default {
    name: 'DesignerToolbar',

    inject: ['deleteWidget'],

    props: {
      selectedWidget: {
        type: Object,
        default: () => null,
      },
      show: { type: Boolean, default: true },
    },

    computed: {
      widgetType() {
        if (
          this.selectedWidget?.itemType === WIDGET_TYPES.TEXT ||
          this.selectedWidget?.object.type === WIDGET_TYPES.TEXT
        ) {
          return WIDGET_TYPES.TEXT;
        }
        return null;
      },

      fixZIndexes() {
        return this.$store.state.templateDesigner.fixZIndexes;
      },

      isDuplicateSupported() {
        return this.widgetType === WIDGET_TYPES.TEXT;
      },

      containsGroupOverrideWidget() {
        return this.$store.getters.hasAnyGroupOverride;
      },

      widgetsByZIndex() {
        return this.$store.getters.getWidgetsByZIndexInTemplates;
      },

      widgetCurrentIndex() {
        return this.widgetsByZIndex.findIndex(
          (widget) => this.selectedWidget?.object.wid === widget.object.wid,
        );
      },

      isWidgetLockedByOverride() {
        const isScreenOverrideMode = !!this.$route.query.screen && !this.$route.query.group;
        return isScreenOverrideMode && this.containsGroupOverrideWidget;
      },

      isMoveUpDisabled() {
        return this.widgetCurrentIndex === this.widgetsByZIndex.length - 1;
      },

      isMoveDownDisabled() {
        return this.widgetCurrentIndex === 0;
      },

      widgetPosition() {
        return this.selectedWidget?.position || { x: 0, y: 0, width: 100 };
      },

      isWidgetMoving() {
        return (
          this.$store.state.templateDesigner.refreshingTemplateStatus !==
          REFRESH_TEMPLATE_STATUS.STAND_BY
        );
      },

      isWidgetLocked() {
        return !!this.selectedWidget?.position.isLocked;
      },

      style() {
        return {
          left: `calc(${`${this.widgetPosition.x}% + ${this.widgetPosition.width / 2}%`})`,
          top: `calc(${`${this.widgetPosition.y}% - 48px`})`,
        };
      },
    },

    methods: {
      duplicateWidget() {
        if (this.widgetType === WIDGET_TYPES.TEXT) {
          const newWidget = duplicateTemplateTextWidget(this.selectedWidget);

          this.$store.commit(TEMPLATE_DESIGNER_DUPLICATE_WIDGET, newWidget);
        }
      },

      fixIndexes() {
        this.widgetsByZIndex.forEach((widget, index) => {
          const currentZIndex = widget.position.zIndex;

          if (currentZIndex === index) return;

          const widgetToUpdate = {
            ...widget,
            position: {
              ...widget.position,
              zIndex: index,
            },
          };

          this.$store.commit(TEMPLATE_DESIGNER_SET_ZINDEX, {
            widgetsToUpdate: [widgetToUpdate],
            widgetToSelect: widgetToUpdate,
            selectWidget: false,
          });

          if (this.selectedWidget.object.wid === widget.object.wid) {
            this.$store.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, {
              ...widget,
              position: {
                ...widget.position,
                zIndex: index,
              },
            });
          }
        });
      },

      async changeZIndex(value) {
        if (this.fixZIndexes) {
          this.fixIndexes(); // Indexes were broke for a few months so we have this kind of setting 0 0 2 3 4 6 6 that changeZIndex function can't handle
          this.$store.commit(SET_SAVE_Z_INDEXES, false);
          return;
        }

        const widgetToMove = this.widgetsByZIndex[this.widgetCurrentIndex + value];

        let oldIndex = this.selectedWidget.position.zIndex;
        let newIndex = widgetToMove.position.zIndex;

        if (newIndex === oldIndex && newIndex > 0) {
          newIndex = newIndex + value;
        }

        if (newIndex === oldIndex && oldIndex <= 0) {
          oldIndex = value > 0 ? 0 : 1;
          newIndex = value > 0 ? 1 : 0;
        }

        const widgetToMoveUpdated = {
          ...widgetToMove,
          position: {
            ...widgetToMove.position,
            zIndex: oldIndex,
          },
        };

        const currentWidget = {
          ...this.selectedWidget,
          position: {
            ...this.selectedWidget.position,
            zIndex: newIndex,
          },
        };

        this.$store.commit(TEMPLATE_DESIGNER_SET_ZINDEX, {
          widgetsToUpdate: [widgetToMoveUpdated, currentWidget],
          widgetToSelect: currentWidget,
          selectWidget: true,
        });
      },

      updateLock() {
        const updatedWidget = {
          ...this.selectedWidget,
          position: {
            ...this.selectedWidget.position,
            isLocked: !this.isWidgetLocked,
          },
        };

        this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget: updatedWidget });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .styled-button {
    display: flex;
    padding: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }

    &:active {
      background-color: $borderGrey;
    }
  }

  .designer-toolbar {
    z-index: 1200;
    background-color: #fff;
    color: $iconsBlack;
    position: absolute;
    padding: 4px 8px;
    height: 40px;
    border-radius: 4px;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  i.disabled {
    color: $fillGrey !important;
  }

  .toolbar-group,
  .buttons-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
</style>
