<template>
  <div class="playlist-widget-panel scrollbar">
    <div class="form-group name layout-input">
      <label for="playlist-name">Area Name</label>
      <input
        class="form-control"
        type="text"
        id="playlist-name"
        placeholder="Name"
        v-model="value.object.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <ImageMode
      class="picture-mode"
      v-model="value.object.imageMode"
      label="Pictures display mode"
      @input="updatePlaylist"
    />

    <PlaylistPreview :playlist="playlist" />

    <button type="button" class="button-2-primary new w-100" @click="openPlaylistItemsPanel">
      <i class="material-icons-outlined align-middle">edit</i>
      Edit playlist
    </button>
  </div>
</template>

<script>
  import ImageMode from '@/components/layoutDesigner/editor/settingPanel/formControls/ImageMode.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import LayoutPlaylistItems from '@/components/layoutDesigner/editor/settingPanel/playlist/LayoutPlaylistItems.vue';

  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';
  import PlaylistPreview from '@/components/layoutDesigner/editor/settingPanel/playlist/PlaylistPreview.vue';

  import {
    LAYOUT_DESIGNER_UPDATE_WIDGET,
    SET_PLAYLIST_ITEMS_PANEL,
  } from '@/store/actions/layoutDesigner';
  import { SET_IS_PLAYLIST_PREVIEW_ON } from '@/store/actions/playlist';
  import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';

  export default {
    name: 'PlaylistWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ImageMode,
      PixelIcon,
      LockIcon,
      LayoutPlaylistItems,
      PlaylistPreview,
    },

    mounted() {
      this.$store.commit(SET_IS_PLAYLIST_PREVIEW_ON, false);
    },

    computed: {
      playlist() {
        return this.value;
      },
    },

    methods: {
      openPlaylistItemsPanel() {
        this.$store.commit(SET_PLAYLIST_ITEMS_PANEL, true);
      },

      updatePlaylist() {
        this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, {
          widget: this.value,
          selectWidget: false,
        });
        this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .playlist-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-group {
      margin-bottom: 0;
    }
  }
</style>
