<template>
  <div class="screens-view-container" id="PlaylistsView">
    <div class="content">
      <div class="content-body" v-if="!loadingGroups && !loadingData">
        <div class="screen-groups">
          <div class="screen-groups-header section-header">
            <div class="header-top">
              <div class="title">Screen Groups</div>
              <i
                class="material-icons-outlined align-middle button"
                @click="openScreenGroupCreateModal"
                title="Add new screen group"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <SearchButton v-model="screenGroupSearchQuery" />
            </div>
          </div>
          <div class="screen-groups-body scrollbar">
            <div
              class="all-screens group-card"
              :class="{
                active: !selectedRootGroup,
                focused: !selectedRootGroup && !selectedScreen,
              }"
              @click="handleSelectAllScreens"
            >
              <div class="all-header">
                <div class="title">
                  Ungrouped screens <span>({{ this.allScreensCount }})</span>
                </div>

                <ScreenRotationIcons
                  :verticalScreens="allScreensVerticalCount"
                  :horizontalScreens="allScreensHorizontalCount"
                  :showEmptyScreens="true"
                />
              </div>

              <ConnectionLights
                :connected="allScreensConnectedCount"
                :unknown="allScreensUnknownCount"
                :disconnected="allScreensDisconnectedCount"
                :size="12"
                :show-empty-lights="true"
              />
            </div>
            <GroupCard
              v-for="group in filteredScreenGroups.filter(
                (group) => !group.parent_id && !group.locked,
              )"
              :key="group.id"
              :group="group"
              :all-groups="screenGroups"
              :related-screens="getRelatedScreens(group.id)"
              :selected-group="selectedGroup"
              :selected-root-group="selectedRootGroup"
              :active="selectedRootGroup?.id === group.id"
              :focused="
                selectedRootGroup?.id === group.id &&
                selectedGroup?.id === group.id &&
                !selectedScreen
              "
              @clickHeader="handleHeaderClicked"
              @selectGroup="handleGroupSelect"
              @deleteGroup="openDeleteGroupModal"
            />
            <Tip title="Screen Groups" v-if="screenGroups.length <= 0">
              No screen groups found. Click the create screen button to add a new screen group.
            </Tip>
          </div>
        </div>
        <div class="screens">
          <div class="screens-header section-header">
            <div class="header-top">
              <div class="title">Screens</div>
              <i
                class="material-icons-outlined align-middle button"
                @click="() => showCreateScreenModal(true)"
                title="Add new screen"
              >
                add
              </i>
            </div>
            <div class="header-bottom">
              <i
                class="material-icons-outlined align-middle button"
                :class="{ active: screenFiltersOpen }"
                @click="toggleScreenFilters"
              >
                filter_alt
              </i>
              <SearchButton v-model="screensSearchQuery" />
            </div>
            <div class="header-filters" v-if="screenFiltersOpen">
              <SelectFilter
                label="Status"
                :options="[
                  { label: 'All', value: 'all' },
                  { label: 'Connected', value: 'connected' },
                  { label: 'Disconnected', value: 'disconnected' },
                  { label: 'Unknown', value: 'unknown' },
                ]"
                v-model="screenStatusFilter"
              />
              <SelectFilter
                label="Orientation"
                :options="[
                  { label: 'All', value: 'all' },
                  { label: 'Vertical', value: 'vertical' },
                  { label: 'Horizontal', value: 'horizontal' },
                ]"
                v-model="screenOrientationFilter"
              />
            </div>
          </div>
          <div class="screens-body scrollbar" v-if="groupScreens.length > 0">
            <ScreenCard
              v-for="screen in filteredGroupScreens"
              :screen="screen"
              :selected="selectedScreen?.id === screen.id"
              :key="screen.id"
              :screen-group-name="getGroup(screen.screen_group)?.name"
              @selectScreen="handleSelectScreen"
            />
          </div>
          <div class="screens-body" v-else-if="loadingScreens[selectedRootGroup?.id]">
            <Loader />
          </div>
          <div class="screens-body scrollbar" v-else>
            <Tip title="Screens">
              No screens found. Click the create screen button to add a new screen.
            </Tip>
          </div>
        </div>
      </div>
      <div class="content-body loading" v-else>
        <Loader />
      </div>
    </div>
    <ScreenPlaceholderSidebar
      v-if="
        !loadingData &&
        !loadingGroups &&
        !selectedRootGroup &&
        !selectedScreen &&
        groupScreens.length === 0
      "
    />
    <ScreensSidebar
      v-else-if="
        !loadingGroups &&
        !loadingData &&
        !selectedRootGroup &&
        !selectedScreen &&
        groupScreens.length > 0
      "
    />
    <ScreenGroupSidebar
      v-else-if="!loadingGroups && !loadingData && selectedRootGroup && !selectedScreen"
      :key="selectedGroup.id"
      @updateGroup="handleGroupUpdated"
    />
    <ScreenSidebar
      v-else-if="!!selectedScreen"
      :screen="selectedScreen"
      :screenGroups="screenGroups"
      :key="selectedScreen?.id"
    />
    <CreateGroupModal ref="createGroupModal" :screen-groups="screenGroups" />
    <DeleteGroupModal
      ref="deleteGroupModal"
      :screenGroupName="selectedGroup?.name"
      :screenGroupId="selectedGroup?.id"
      @groupDeleted="handleGroupDeleted"
    />
    <DeleteScreenModal
      :screenName="selectedScreen?.name"
      :screenId="selectedScreen?.id"
      ref="deleteScreenModal"
      @screenDeleted="handleScreenDeleted"
    />
    <ScreenAddModal
      v-if="isShowCreateScreenModal"
      ref="createScreenModal"
      :screenGroupName="selectedGroup?.name"
      :screenGroupId="selectedGroup?.id"
      :is-open="isShowCreateScreenModal"
      @screenAdded="fetchData"
      @close="() => showCreateScreenModal(false)"
    />
  </div>
</template>

<script>
  import {
    SCREENS_FETCH,
    SCREENS_RESET,
    SCREEN_GROUPS_FETCH,
    SET_SCREEN,
    SET_SCREEN_GROUP,
    SCREENS_STATUS_FETCH,
  } from '@/store/actions/screens';
  import { mapState } from 'vuex';
  import Loader from '@/components/common/Loader.vue';
  import SearchButton from '@/components/common/SearchButton.vue';
  import Tip from '../Tip.vue';
  import ScreenRotationIcons from '@/components/common/screens/ScreenRotationIcons.vue';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';
  import ScreenCard from '@/components/common/screens/ScreenCard.vue';
  import GroupCard from '@/components/common/screens/GroupCard.vue';
  import ScreenPlaceholderSidebar from './ScreenPlaceholderSidebar.vue';
  import ScreensSidebar from './ScreensSidebar.vue';
  import ScreenGroupSidebar from './ScreenGroupSidebar.vue';
  import ScreenSidebar from './ScreenSidebar.vue';
  import CreateGroupModal from './CreateGroupModal.vue';
  import DeleteGroupModal from './DeleteGroupModal.vue';
  import ScreenAddModal from './ScreenAddModal.vue';
  import * as ScreensHelper from '@/helpers/screens';
  import DeleteScreenModal from './DeleteScreenModal.vue';
  import SelectFilter from '@/components/content/SelectFilter.vue';
  import { CONNECTION_STATUS_INTERVAL } from '@/constant/const';

  export default {
    name: 'ScreensView',

    components: {
      Loader,
      SearchButton,
      Tip,
      ScreenRotationIcons,
      ConnectionLights,
      ScreenCard,
      GroupCard,
      ScreenPlaceholderSidebar,
      ScreensSidebar,
      ScreenGroupSidebar,
      ScreenSidebar,
      CreateGroupModal,
      DeleteGroupModal,
      ScreenAddModal,
      DeleteScreenModal,
      SelectFilter,
    },

    data() {
      return {
        loadingData: false,
        screensSearchQuery: '',
        screenGroupSearchQuery: '',

        // Filters
        screenFiltersOpen: false,
        screenStatusFilter: 'all',
        screenOrientationFilter: 'all',

        selectedRootGroup: null,

        selectedGroup: this.$store.state.screens.screenGroup,
        selectedScreen: this.$store.state.screens.screen,

        screenData: null,

        isShowCreateScreenModal: false,
        interval: undefined,
      };
    },

    computed: {
      ...mapState({
        loadingGroups: (state) => state.screens.screenGroups.loading,
        errorGroups: (state) => state.screens.screenGroups.error,
        screenGroups: (state) => state.screens.screenGroups.data,
        loadingScreens: (state) => state.screens.screens.loading,
        errorScreens: (state) => state.screens.screens.error,
        screens: (state) => state.screens.screens.data,
        dashboardStatistics: (state) => state.dashboardStatistics,
      }),

      screensStatus() {
        return this.$store.state.screens.screensStatus.statuses;
      },

      groupScreens() {
        let groupId = this.selectedGroup ? this.selectedGroup.id : 'Ungrouped';
        if (groupId === 'Ungrouped') {
          return this.screens[groupId] || [];
        }
        return this.getRelatedScreens(groupId);
      },

      unGroupedScreens() {
        return this.screens['Ungrouped'] || [];
      },

      filteredScreenGroups() {
        if (this.screenGroupSearchQuery === '') {
          return this.screenGroups;
        }
        return this.screenGroups.filter((group) => {
          return group.name.toLowerCase().includes(this.screenGroupSearchQuery.toLowerCase());
        });
      },

      filteredGroupScreens() {
        let filteredScreens = this.groupScreens;

        // Filter by status
        if (this.screenStatusFilter === 'connected') {
          filteredScreens = filteredScreens.filter((screen) => {
            return this.screensStatus[screen.id].connection_status === 2;
          });
        } else if (this.screenStatusFilter === 'disconnected') {
          filteredScreens = filteredScreens.filter((screen) => {
            return this.screensStatus[screen.id].connection_status === 0;
          });
        } else if (this.screenStatusFilter === 'unknown') {
          filteredScreens = filteredScreens.filter((screen) => {
            return (
              this.screensStatus[screen.id].connection_status !== 0 &&
              this.screensStatus[screen.id].connection_status !== 2
            );
          });
        }

        // Filter by orientation
        if (this.screenOrientationFilter === 'vertical') {
          filteredScreens = filteredScreens.filter((screen) => {
            return screen?.screen_information?.orientation === 'portrait';
          });
        } else if (this.screenOrientationFilter === 'horizontal') {
          filteredScreens = filteredScreens.filter((screen) => {
            return screen?.screen_information?.orientation === 'landscape';
          });
        }

        if (this.screensSearchQuery !== '') {
          return filteredScreens.filter((screen) => {
            return screen.name.toLowerCase().includes(this.screensSearchQuery.toLowerCase());
          });
        }
        return filteredScreens;
      },

      allScreensCount() {
        return this.unGroupedScreens.length;
      },

      allScreensVerticalCount() {
        return this.unGroupedScreens.filter((screen) => {
          return (
            !screen.screen_information?.orientation ||
            ['270d', 'portrait'].includes(screen?.screen_information?.orientation)
          );
        }).length;
      },

      allScreensHorizontalCount() {
        return this.unGroupedScreens.filter((screen) => {
          return (
            !!screen.screen_information &&
            ['180d', 'landscape'].includes(screen?.screen_information?.orientation)
          );
        }).length;
      },

      allScreensConnectedCount() {
        return this.$store.state.screens.screensStatus.data.filter(
          (screen) => screen.screen_group === null && screen.connection_status === 2,
        ).length;
      },

      allScreensDisconnectedCount() {
        return this.$store.state.screens.screensStatus.data.filter(
          (screen) => screen.screen_group === null && screen.connection_status === 0,
        ).length;
      },

      allScreensUnknownCount() {
        return this.$store.state.screens.screensStatus.data.filter(
          (screen) => screen.screen_group === null && screen.connection_status === 1,
        ).length;
      },
    },
    methods: {
      async fetchData(silent = false) {
        this.loadingData = !silent;
        await this.fetchScreenGroups();
        await this.fetchScreens();
        await this.fetchScreensStatus();
        this.repopulateSelected();
        this.loadingData = false;
      },
      async fetchScreenGroups() {
        await this.$store.dispatch(SCREEN_GROUPS_FETCH);
      },
      async fetchScreens() {
        // Fetch ungrouped
        await this.$store.dispatch(SCREENS_FETCH, 'Ungrouped');

        // Fetch all other screens
        await this.$store.dispatch(SCREENS_FETCH, 'all');
      },

      async fetchScreensStatus() {
        await this.$store.dispatch(SCREENS_STATUS_FETCH);
      },

      // repopulate selected groups
      repopulateSelected() {
        if (this.selectedGroup) {
          this.selectedGroup = this.getGroup(this.selectedGroup.id);
        }

        if (this.selectedRootGroup) {
          this.selectedRootGroup = this.getGroup(this.selectedRootGroup.id);
        } else if (this.selectedGroup && !this.selectedGroup.parent_id) {
          this.selectedRootGroup = this.selectedGroup;
        } else if (this.selectedGroup && this.selectedGroup.parent_id) {
          this.selectedRootGroup = this.getRootGroup(this.selectedGroup);
        }

        if (this.selectedScreen) {
          this.selectedScreen = this.getScreen(this.selectedScreen.id);
        }
      },

      handleSelectAllScreens() {
        this.selectedRootGroup = null;
        this.selectedGroup = null;
        this.selectedScreen = null;
      },

      handleSelectScreen(screen) {
        this.selectedScreen = screen;
      },

      handleHeaderClicked(group) {
        this.selectedScreen = null;
        this.selectedRootGroup = group;
        this.selectedGroup = group;
      },

      handleGroupSelect(root, group) {
        this.selectedScreen = null;
        this.selectedRootGroup = root;
        this.selectedGroup = group;
      },

      handleGroupDeleted() {
        this.selectedRootGroup = null;
        this.selectedGroup = null;
        this.$store.commit(SCREENS_RESET);
        this.fetchData(true);
      },

      handleScreenDeleted() {
        this.selectedScreen = null;
        this.$store.commit(SCREENS_RESET);
        this.fetchData(true);
      },

      handleGroupUpdated() {
        this.selectedRootGroup = null;
        this.selectedGroup = null;
        this.fetchData(true);
      },

      getGroupChildren(groupId) {
        return this.screenGroups.filter((group) => group.parent_id === groupId);
      },

      getGroup(groupId) {
        return this.screenGroups.find((group) => group.id === groupId);
      },

      //  Get children groups recursively
      getGroupChildrenTree(groupId) {
        return ScreensHelper.getGroupChildrenTree(this.screenGroups, groupId);
      },

      getGroupFlatten(groupId) {
        return ScreensHelper.getGroupFlatten(this.screenGroups, groupId);
      },

      getRelatedScreens(groupId) {
        return ScreensHelper.getRelatedScreens(this.screenGroups, this.screens['all'], groupId);
      },

      getScreen(screenId) {
        if (!this.selectedGroup) {
          return this.screens['Ungrouped'].find((screen) => screen.id === screenId);
        }
        return this.getRelatedScreens(this.selectedGroup.id).find(
          (screen) => screen.id === screenId,
        );
      },

      toggleScreenFilters() {
        this.screenFiltersOpen = !this.screenFiltersOpen;
      },

      openDeleteGroupModal(root, group) {
        this.selectedRootGroup = root;
        this.selectedGroup = group;
        this.$refs.deleteGroupModal.open();
      },

      openDeleteScreenModal(screen) {
        this.selectedScreen = screen;
        this.$refs.deleteScreenModal.open();
      },

      showCreateScreenModal(value) {
        this.isShowCreateScreenModal = value;
      },

      openScreenGroupCreateModal() {
        this.$refs.createGroupModal.open();
      },

      getRootGroup(group) {
        let currentGroup = group;

        while (currentGroup.parent_id) {
          currentGroup = this.screenGroups.find((g) => g.id === currentGroup.parent_id);
        }

        return currentGroup;
      },
    },

    provide() {
      return {
        getGroups: () => this.screenGroups,
        getGroupScreens: () => this.groupScreens,
        getCurrentScreenGroup: () => this.selectedGroup,
        openScreenModal: () => this.showCreateScreenModal(true),
        openGroupModal: () => this.openScreenGroupCreateModal(),
        openDeleteGroupModal: (groupId) => this.openDeleteGroupModal(groupId),
        reloadScreensData: this.fetchData,
        openDeleteScreenModal: (screen) => this.openDeleteScreenModal(screen),
      };
    },

    async created() {
      await this.fetchData();
      this.$store.commit(SET_SCREEN, null);
      this.$store.commit(SET_SCREEN_GROUP, null);

      this.interval = setInterval(this.fetchScreensStatus, CONNECTION_STATUS_INTERVAL);
    },

    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = undefined;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .screens-view-container {
    font-family: 'Poppins';
    display: grid;
    grid-template-columns: auto 376px;
    width: 100%;
    height: 100vh;
    color: #151515;
    background-color: #f7f8f7;
    .content {
      height: 100%;
      overflow: hidden;
      .content-body {
        padding: 48px 10px 0px 24px;
        display: flex;
        height: 100%;
        overflow: hidden;

        &.loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .screen-groups,
        .screens {
          width: 50%;
        }
        .screens {
          border-left: 2px solid #f0f0f0;
        }

        .screen-groups {
          display: flex;
          flex-direction: column;
          .screen-groups-header {
            box-sizing: border-box;
            padding: 0px 16px;
          }
          .screen-groups-body {
            flex: 1;
            box-sizing: border-box;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 28px 16px;
          }
        }

        .screens {
          display: flex;
          flex-direction: column;
          .screens-header {
            padding: 0px 16px;
          }
          .screens-body {
            flex: 1;
            padding: 28px 16px;
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            gap: 16px;
            .item {
              height: fit-content;
              width: 100%;
            }
          }
        }
      }
    }

    .section-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 4px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      font-family: 'Whyte Inktrap';
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          color: #6a6b6a;
        }
      }

      .header-bottom {
        display: flex;
        align-items: flex-start;
        vertical-align: middle;
        color: #6a6b6a;
        gap: 10px;

        .button.active {
          color: $primaryRed;
        }
        &.space {
          height: 24px;
        }
      }
      .header-filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(24px, auto);
        gap: 12px;
      }
    }
  }

  .all-screens {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 500;
    border: 1px solid transparent;
    &.active {
      box-shadow:
        0px 0px 0px 2px rgba(227, 0, 58, 0.2),
        0px 0px 16px rgb(21 21 21 / 2%);
    }
    &.focused {
      border: 1px solid $primaryRed;
    }

    .all-header {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 16px;
        line-height: 24px;

        span {
          font-weight: normal;
          color: $secondaryText;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
