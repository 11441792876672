import { render, staticRenderFns } from "./TextWidget.vue?vue&type=template&id=672ef8e7&scoped=true"
import script from "./TextWidget.vue?vue&type=script&lang=js"
export * from "./TextWidget.vue?vue&type=script&lang=js"
import style0 from "./TextWidget.vue?vue&type=style&index=0&id=672ef8e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672ef8e7",
  null
  
)

export default component.exports