import { Colors } from '@/constant/theme';
import { TenantsWidgetObject } from '@/types/widgets/tenantsWidget/tenantsWidget';
import { TimeUnitsType } from '@/types/common';

const { LINE_GREY, PRIMARY_TEXT, WHITE_COLOR } = Colors;

export const tenantsDefaultOptions: Partial<TenantsWidgetObject> = {
  name: 'Tenants',

  address: null,
  folder: null,
  refreshTime: 1,
  timeUnit: TimeUnitsType.DAYS,

  allFloorsVisible: true,
  floors: null,

  // Slider
  floorsOnSlide: 4,
  verticalMode: false,
  interval: 15,
  designType: 1,
  backgroundColor: WHITE_COLOR,
  opacity: 100,

  // Floors Style
  floorFont: 'Arial',
  floorFontSize: 13,
  floorFontColor: PRIMARY_TEXT,

  // Floors Numbers Style
  numberFont: 'Arial',
  numberFontSize: 13,
  numberFontColor: PRIMARY_TEXT,
  numberBackgroundColor: WHITE_COLOR,

  // Rooms Style
  roomsFont: 'Arial',
  roomsFontSize: 13,
  roomsFontColor: PRIMARY_TEXT,

  // Title Style
  title: 'Tenants',
  titleFont: 'Arial',
  titleFontSize: 14,
  titleFontColor: PRIMARY_TEXT,
  titleBackgroundColor: LINE_GREY,
};
