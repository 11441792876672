import config from '../config';
import axios from 'axios';
import { Departure } from '@/types/api/transport';

export async function getStopLocations(search: string): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/traffiklab/stop-locations`, {
    params: { search: search },
  });

  return response.data;
}

export async function getDepartures(
  stopId: string,
  transportTypes: unknown,
  lang: string,
  departuresAfter: string,
  duration: number = 60,
): Promise<Departure[]> {
  const apiUrl = `${config.apiUrl}userapi/traffiklab/timetables/departures`;

  const response = await axios.get(apiUrl, {
    params: {
      stopId: stopId,
      transportTypes: transportTypes,
      lang: lang,
      departuresAfter: departuresAfter,
      duration: duration,
    },
  });

  return response.data;
}
