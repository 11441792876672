<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="widget-item clock-widget" :id="widget.object.wid" :style="style">
      <slot></slot>

      <div class="clock-widget-clock-wrapper" v-if="!loading">
        <div class="current-time-string">
          <span>{{ time }}</span>
        </div>

        <div v-if="widget.object.showDate" class="current-date-string">
          <span>{{ date }}</span>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  const CLOCK_TIME = 'HH:mm';
  const CLOCK_DATE = 'MMM D';

  const timeFormatter = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const dateFormatter = new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
  });

  export default {
    name: 'ClockWidget',

    mixins: [simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
    },

    props: {
      widget: {
        type: Object,
      },
    },

    data() {
      return {
        loading: true,
        currentTime: null,
        timer: null,
      };
    },

    computed: {
      style() {
        return {
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            Number(this.widget.object.backgroundOpacity) / 100,
          ),
          color: getRBGAColor(
            this.widget.object.textColor,
            Number(this.widget.object.opacity) / 100,
          ),
          fontSize: `${this.scaleTemplateValue(this.widget.object.textSize || 20)}px`,
          textAlign: this.widget.object.alignment,
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        };
      },

      time() {
        return timeFormatter.format(this.currentTime);
      },

      date() {
        return dateFormatter.format(this.currentTime);
      },

      position() {
        return this.getPosition(this.widget);
      },
    },

    methods: {
      updateClock() {
        const now = new Date();
        this.currentTime = now;
      },

      showDate() {
        return this.clockObj.format(CLOCK_DATE);
      },

      showTime() {
        return this.clockObj.format(CLOCK_TIME);
      },

      clockTick() {
        this.updateClock();
      },
    },

    mounted() {
      this.updateClock();
      this.loading = false;

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(this.updateClock, 1000);
    },
  };
</script>

<style lang="scss" scoped>
  .clock-widget-clock-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
</style>
