import { getCurrentInstance } from 'vue';
const $ = window.jQuery;

import {
  simpleTypeMixin,
  globalFiltersMixin,
  globalMethodsMixin,
  youtubeIdFromUrl,
  getSimpleType,
} from './mixins';

import { globalFilters } from './filters';

function cloneObj(o) {
  return o === null ? null : JSON.parse(JSON.stringify(o));
}

function strPad(pad, str, padLeft = true) {
  if (typeof str === 'undefined') return pad;
  if (padLeft) {
    return (pad + str).slice(-pad.length);
  } else {
    return (str + pad).substring(0, pad.length);
  }
}

export {
  simpleTypeMixin,
  globalFiltersMixin,
  globalFilters,
  globalMethodsMixin,
  youtubeIdFromUrl,
  getSimpleType,
  cloneObj,
  strPad,
};

export const globalToasts = [
  {
    name: 'general_error',
    toast: (payload) => {
      if (!payload.message) {
        return 'Undefined error';
      }
      return `<div class="notification-container">
                <h5 class="notification-page-name">Error</h5>
                <div class="notification-message">${payload.message}</div>
              </div>`;
    },
    params: {
      type: 'error',
      duration: 10000,
      icon: 'close',
      theme: 'primary',
    },
  },
  {
    name: 'general_error_stick',
    toast: (payload) => {
      if (!payload.message) {
        return 'Undefined error';
      }
      return `
            <div class="notification-container">
              <h5 class="notification-page-name">Error</h5>
              <div class="notification-message">${payload.message}</div>
            </div>
            `;
    },
    params: {
      type: 'error',
      duration: 30000,
      icon: 'close',
      theme: 'primary',
      action: {
        text: 'Close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    },
  },

  {
    name: 'general_notification',
    toast: (payload) => {
      if (!payload.message) {
        return 'Undefined notification';
      }

      return `
        <div class="notification-container">
          <h5 class="notification-page-name">Info</h5>
          <div class="notification-message">${payload.message}</div>
        </div>
      `;
    },
    params: {
      type: 'info',
      duration: 4000,
      theme: 'primary',
      icon: 'info',
    },
  },

  {
    name: 'general_success',
    toast: (payload) => {
      if (!payload.message) {
        return 'Undefined success';
      }
      return `<div class="notification-container">
                <h5 class="notification-page-name">Success</h5>
                <div class="notification-message">${payload.message}</div>
            </div>`;
    },
    params: {
      type: 'success',
      duration: 4000,
      icon: 'check',
      theme: 'primary',
    },
  },

  {
    name: 'general_warning',
    toast: (payload) => {
      if (!payload.message) {
        return 'Undefined warning';
      }
      return `<div class="notification-container">
                <h5 class="notification-page-name">Warning</h5>
                <div class="notification-message">${payload.message}</div>
              </div>`;
    },

    params: {
      type: 'warning',
      duration: 10000,
      icon: 'priority_high',
      theme: 'primary',
    },
  },
];

export const sxsw = {
  full_bleed: function (boxWidth, boxHeight, imgWidth, imgHeight) {
    // Calculate new height and width...
    var initW = imgWidth;
    var initH = imgHeight;
    var ratio = initH / initW;

    imgWidth = boxWidth;
    imgHeight = boxWidth * ratio;

    // If the video is not the right height, then make it so...
    if (imgHeight < boxHeight) {
      imgHeight = boxHeight;
      imgWidth = imgHeight / ratio;
    }

    //  Return new size for video
    return {
      width: imgWidth,
      height: imgHeight,
    };
  },

  init: function () {
    var browserHeight = Math.round($(window).height());
    var browserWidth = Math.round($(window).width());
    var videoHeight = $('video').height();
    var videoWidth = $('video').width();

    var new_size = sxsw.full_bleed(browserWidth, browserHeight, videoWidth, videoHeight);

    $('video').width(new_size.width).height(new_size.height);
  },
};

export const useToasted = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$toasted;
};

export function divideArray(array, size = 1) {
  return array.length > size
    ? [array.slice(0, size), ...divideArray(array.slice(size), size)]
    : [array];
}
