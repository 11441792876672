<template>
  <SidebarContainer class="sidebar-container">
    <template #default v-if="isLoading">
      <Loader />
    </template>
    <template #default v-else>
      <div class="sidebar-inner">
        <div class="preview-container" id="ScreensView">
          <div class="preview-content" :style="{ aspectRatio: previewAspectRatio }">
            <Player
              v-if="screen.layout && !showPreviewModal"
              ref="player"
              :layout_id="screen.layout"
              :key="key"
              isPreview
              :screenId="screen.id"
            />
            <div
              v-if="!screen.layout"
              class="w-100 h-100 d-flex align-items-center justify-content-center text-danger font-weight-bold"
            >
              This screen has no layout attached to it.
            </div>
          </div>

          <div class="preview-footer">
            <div class="online-status">
              <ConnectionLights
                :connected="isConnected ? 1 : 0"
                :unknown="isUnknown ? 1 : 0"
                :disconnected="isDisconnected ? 1 : 0"
                :size="12"
                hideNumbers
                showText=""
              />
            </div>

            <div class="right">
              <RotateScreenButton
                title="Rotate Screen"
                :isRotating="isRotating"
                @rotateScreens="rotateScreen"
              />

              <div v-if="isRefreshing">
                <Loader :size="20" />
              </div>
              <i
                v-else
                class="material-icons align-middle button"
                title="Restart Screen"
                @click="refreshScreen"
              >
                refresh
              </i>
              <i
                class="material-icons-outlined align-middle button"
                title="Open Full Screen"
                @click="openPreviewOverlay"
              >
                fullscreen
              </i>
            </div>
          </div>
        </div>

        <BaseInput id="screenName" label="Screen Name" v-model="screenName" />

        <BaseSelect id="screenLayout" label="Screen Layout" v-model="screenLayout">
          <option value="null">None</option>
          <option
            v-for="layout in layouts"
            :key="layout.layout_id"
            :value="layout.layout_id"
            :selected="layout.layout_id === screenLayout"
          >
            {{ layout.settings.name }}
          </option>
        </BaseSelect>

        <BaseSelect id="screenGroup" label="Screen Group" v-model="screenGroup">
          <option :value="null">None</option>
          <option
            v-for="group in screenGroups"
            :key="group.id"
            :value="group.id"
            :selected="screenGroup === group.id"
          >
            {{ group.name }}
          </option>
        </BaseSelect>

        <div class="dropdown-section">
          <SidebarDropdown
            v-if="screen.screen_information"
            title="Info"
            :defaultOpen="true"
            :items="screenInfoItems"
          />
        </div>
      </div>

      <LayoutsPreviewModal
        v-if="showPreviewModal"
        :screenId="screen?.id"
        @closeModal="handleClosePreview"
      />
    </template>

    <template v-if="!isLoading" #buttons>
      <BaseButton
        variant="outline"
        class="mt-3"
        @click="handleEditScreenClick"
        :icon="isScreenOverridable ? 'edit' : 'lock'"
        :disabled="!screen.layout"
      >
        Edit Screen
      </BaseButton>

      <BaseButton icon="save" @click="saveScreenData" :loading="isSaving">
        Save Changes
      </BaseButton>

      <DeleteScreenModal
        @closeModal="closeScreenModal"
        :screenId="screen.id"
        v-if="showDeleteScreenModal"
      />

      <Modal
        v-show="showOverrideBlockedModal"
        noCancelButton
        title="Access Denied"
        :okFunction="() => (showOverrideBlockedModal = false)"
      >
        <template v-slot:body>
          <div class="modal-description">
            You can't override this screen because your role doesn't have enough access. Ask your
            admin your role's access.
          </div>
        </template>
      </Modal>
    </template>
  </SidebarContainer>
</template>

<script>
  import moment from 'moment';
  import { sortBy } from 'lodash';

  import { apiGetScreenOverridePermission, rotateScreen } from '@/api/screens';
  import { apiReadLayouts } from '@/api/layouts';

  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';
  import DeleteScreenModal from '@/components/screens/DeleteScreenModal.vue';
  import Player from '@/components/player/Index.vue';
  import Loader from '@/components/common/Loader.vue';
  import Modal from '@/components/common/Modal.vue';
  import RotateScreenButton from '@/components/common/screens/RotateScreenButton.vue';
  import SidebarDropdown from '@/components/common/SidebarDropdown.vue';
  import LayoutsPreviewModal from '@/components/layouts/LayoutsPreviewModal.vue';

  import { SCREEN_UPDATE, SCREEN_ADD_LAYOUT, SCREENS_RESTART } from '@/store/actions/screens';

  import { humanFileSize } from '@/helpers/filters';
  import { PublicationType } from '@/types/api/screens';
  import { SET_PREVIEW } from '@/store/actions/preview';

  export default {
    name: 'ScreenSidebar',

    inject: ['reloadScreensData'],

    components: {
      SidebarContainer,
      ConnectionLights,
      DeleteScreenModal,
      Loader,
      Player,
      RotateScreenButton,
      SidebarDropdown,
      LayoutsPreviewModal,
      Modal,
    },

    props: {
      screenGroups: {
        type: Array,
        default: () => [],
      },

      screen: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        key: 1,
        isSaving: false,
        isLoading: false,
        isLoadingPermissions: false,
        showPreviewModal: false,
        isRefreshing: false,
        isRotating: false,
        showDeleteGroupModal: false,
        screenName: '',
        screenLayout: null,
        screenGroup: null,
        showDeleteScreenModal: false,
        showPlaylists: false,
        selectedGroup: '',
        layouts: [],
        isScreenOverridable: true,
        showOverrideBlockedModal: false,
      };
    },

    computed: {
      screenStatus() {
        return this.$store.getters.getScreenStatus(this.screen.id);
      },

      screenStatusName() {
        switch (this.screenStatus) {
          case 0:
            return 'Disconnected';
          case 1:
            return 'Idle';
          case 2:
            return 'Connected';

          default:
            return 'N/A';
        }
      },

      isConnected() {
        return this.screenStatus === 2;
      },
      isDisconnected() {
        return this.screenStatus === 0;
      },
      isUnknown() {
        return this.screenStatus === 1;
      },

      layout() {
        return this.$store.state.player.layout;
      },

      previewAspectRatio() {
        if (!this.layout) return;
        let layout = this.layout;
        let originalRatio = layout.settings.aspectRatio;

        if (!layout.settings.isHorizontal) return originalRatio.split(':').reverse().join('/');

        return originalRatio.replace(':', '/');
      },

      screenInfoItems() {
        if (!this.screen.screen_information) return [];

        const options = [
          {
            name: 'Network Type',
            value: this.screen.screen_information.network_type || 'N/A',
          },
          {
            name: 'Network IP',
            value: this.screen.screen_information.network_ip || 'N/A',
          },
          {
            name: 'Network Gateway',
            value: this.screen.screen_information.network_gateway || 'N/A',
          },
          {
            name: 'Network MAC',
            value: this.screen.screen_information.network_mac || 'N/A',
          },
          {
            name: 'Network Netmask',
            value: this.screen.screen_information.network_netmask || 'N/A',
          },
          {
            name: 'Network DNS',
            value: this.screen.screen_information.network_dns || 'N/A',
          },
          {
            name: 'Network Method',
            value: this.screen.screen_information.network_method || 'N/A',
          },
          {
            name: 'Storage Internal Total',
            value: this.screen.screen_information.storage_internal_total
              ? humanFileSize(this.screen.screen_information.storage_internal_total)
              : 'N/A',
          },
          {
            name: 'Storage Internal Free',
            value: this.screen.screen_information.storage_internal_free
              ? humanFileSize(this.screen.screen_information.storage_internal_free)
              : 'N/A',
          },
          {
            name: 'Storage Internal Used',
            value: this.screen.screen_information.storage_internal_used
              ? humanFileSize(this.screen.screen_information.storage_internal_used)
              : 'N/A',
          },
          {
            name: 'Datetime Current',
            value: this.screen.screen_information.datetime_current
              ? moment(this.screen.screen_information.datetime_current).format('DD-MM-YYYY HH:mm')
              : 'N/A',
          },
          {
            name: 'Datetime Timezone',
            value: this.screen.screen_information.datetime_timezone || 'N/A',
          },
          {
            name: 'Platform Info OS',
            value: this.screen.screen_information.platform_info_os || 'N/A',
          },
          {
            name: 'Platform Info OS Version',
            value: this.screen.screen_information.platform_info_os_version || 'N/A',
          },
          {
            name: 'System Memory Total',
            value: this.screen.screen_information.system_memory_total
              ? humanFileSize(this.screen.screen_information.system_memory_total)
              : 'N/A',
          },
          {
            name: 'System Memory Free',
            value: this.screen.screen_information.system_memory_free
              ? humanFileSize(this.screen.screen_information.system_memory_free)
              : 'N/A',
          },
          {
            name: 'System Memory Used',
            value: this.screen.screen_information.system_memory_used
              ? humanFileSize(this.screen.screen_information.system_memory_used)
              : 'N/A',
          },
          {
            name: 'System Memory Buffer',
            value: this.screen.screen_information.system_memory_buffer
              ? humanFileSize(this.screen.screen_information.system_memory_buffer)
              : 'N/A',
          },
          {
            name: 'System Memory Cached',
            value: this.screen.screen_information.system_memory_cached
              ? humanFileSize(this.screen.screen_information.system_memory_cached)
              : 'N/A',
          },
          {
            name: 'Connection Status',
            value: this.screenStatusName,
          },
          {
            name: 'Connection Status Updated',
            value: this.screen.screen_information.conn_status_updated_time
              ? moment
                  .utc(this.screen.screen_information.conn_status_updated_time)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              : 'N/A',
          },
          {
            name: 'Orientation',
            value: this.screen.screen_information.orientation || 'N/A',
          },
          {
            name: 'Model Name',
            value: this.screen.screen_information.model_name || 'N/A',
          },
          {
            name: 'Serial Number',
            value: this.screen.screen_information.serial_number || 'N/A',
          },
          {
            name: 'Firmware Version',
            value: this.screen.screen_information.firmware_version || 'N/A',
          },
          {
            name: 'SDK Version',
            value: this.screen.screen_information.sdk_version || 'N/A',
          },
          {
            name: 'Player Version',
            value: this.screen.screen_information.player_version || 'N/A',
          },
        ];

        return sortBy(options, [
          function (o) {
            return o.name;
          },
        ]);
      },
    },

    mounted() {
      this.screenName = this.screen.name;
      this.screenLayout = this.screen.layout;
      this.selectedGroup =
        this.screenGroups.find((group) => group.id === this.screen.screen_group) || null;
      this.screenGroup = this.selectedGroup?.id;
      this.fetchAllLayouts();
      this.fetchScreenPermission();
    },

    watch: {
      screen() {
        this.screenName = this.screen.name;
        this.screenLayout = this.screen.layout;
        this.selectedGroup =
          this.screenGroups.find((group) => group.id === this.screen.screen_group) || null;
        this.screenGroup = this.selectedGroup?.id;
      },
    },

    methods: {
      async fetchData(silent = false) {
        this.isLoading = !silent;
        if (this.$refs?.player) this.$refs.player.loadLayout();
        this.key = this.key + 1;
        this.isLoading = false;
      },

      async fetchAllLayouts() {
        try {
          const response = await apiReadLayouts();
          this.layouts = response.data.items;
        } catch (err) {
          console.log(err);
        }
      },

      async fetchScreenPermission() {
        try {
          const response = await apiGetScreenOverridePermission(this.screen.id);
          this.isScreenOverridable = response.data.override_permission;
        } catch (error) {
          console.log(error);
          this.isScreenOverridable = true;
        }
      },

      handleEditScreenClick() {
        if (!this.isScreenOverridable) {
          this.showOverrideBlockedModal = true;
          return;
        }

        this.$router.push({
          name: 'Screen',
          params: {
            screenId: this.screen.id,
          },
        });
      },

      async rotateScreen(orientation = 'portrait') {
        this.isRotating = true;

        try {
          await rotateScreen(this.screen.id, orientation);
          await this.reloadScreensData(true);
          await this.fetchData(true);

          this.$toasted.global.general_success({
            message: `Screen rotation request sent successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to rotate screen' });
        }
        this.isRotating = false;
      },

      async refreshScreen() {
        this.isRefreshing = true;
        try {
          await this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenPublication,
            data: { screen_ids: [this.screen.id] },
          });
          await this.fetchData(true);
          this.$toasted.global.general_success({
            message: `Refresh request sent successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to refresh screens' });
        }
        this.isRefreshing = false;
      },

      async saveScreenData() {
        this.isSaving = true;

        try {
          // Check if screen name is empty
          if (!this.screenName) {
            this.$toasted.global.general_error({
              message: `Screen name cannot be empty`,
            });
            this.isSaving = false;
            return;
          }

          // Check if screen name or group has changed
          if (
            this.screenName !== this.screen.name ||
            this.screenGroup !== this.screen.screen_group
          ) {
            await this.$store.dispatch(SCREEN_UPDATE, {
              screenId: this.screen.id,
              name: this.screenName,
              screen_group: this.screenGroup,
            });
          }

          // Check if layout has changed
          if (this.screenLayout !== this.screen.layout) {
            await this.$store.dispatch(SCREEN_ADD_LAYOUT, {
              screenId: this.screen.id,
              layoutId: this.screenLayout,
            });
          }

          await this.reloadScreensData();
          // Update the key so the layout refreshes
          this.key = this.key + 1;

          this.$toasted.global.general_success({
            message: `Changes successfully saved`,
          });
        } catch (error) {
          this.$toasted.global.general_error({ message: 'Unable to save screen changes' });
        }

        this.isSaving = false;
      },

      handlePlaylistSelection(playlist) {
        this.selectedPlaylist = playlist;
      },

      openContentModal() {
        this.$refs.PlaylistItems.openPlaylistContentModal();
      },

      openPreviewOverlay() {
        this.$store.dispatch(SET_PREVIEW, true);
        this.showPreviewModal = true;
      },

      handleClosePreview() {
        this.$store.dispatch(SET_PREVIEW, false);
        this.showPreviewModal = false;
      },
    },
    created() {
      this.fetchData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .overlay-modal {
    .ui-modal-content {
      aspect-ratio: 16/9;
      width: auto !important;
      padding: 10px !important;
      border-radius: 4px;
      background-color: #fafafa !important;

      .web-player-v2 {
        height: 100%;
        border: 2px solid $primaryRed;
      }
    }
  }
  .sidebar-container {
    .sidebar-inner {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .preview-content {
      min-height: 205px;
      background-color: #eeeeee;

      ::v-deep .web-player-v2 {
        height: 100%;
      }
    }

    .preview-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 20px;
      color: $secondaryText;

      .button {
        &:hover {
          color: $primaryText;
        }
      }
    }
  }

  .online-status {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  .right {
    display: flex;
    gap: 10px;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input,
      select {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }
</style>
