<template>
  <div class="content-browser" @click.stop="deSelectAll">
    <div class="d-flex flex-column">
      <div v-if="!pickerModal" class="row mb-4 mt-4 ml-0 mr-0">
        <div class="col-10"></div>
        <div class="col-2 d-flex align-items-center justify-content-end">
          <img src="@/assets/icon/icn-funnel.png" class="cursor-pointer" @click="filterToggle" />
          <img src="@/assets/img/Sort-1.svg" class="cursor-pointer ml-4" @click="sortContent" />
        </div>
      </div>
      <div v-if="directoryTree.length && !hideBreadcrumbs">
        <div class="pb-3">
          <div class="page-breadcrumb col-9">
            <div class="breadcrumb-content cursor-pointer">
              <span @click.stop="showRootContentPage">Content ></span>
              <span
                v-for="(item, index) in directoryTree"
                :key="index"
                @click.stop="onDirectoryTreeClicked(index)"
                class="cursor-pointer"
              >
                {{ item }}
                <span v-if="index !== directoryTree.length - 1"> > </span>
              </span>
            </div>
            <div class="breadcrumb-info">{{ this.currentCategory.contents.length }} items</div>
          </div>
        </div>
      </div>

      <div v-if="filteredContent.length === 0" class="text-center mt-5">
        <p v-show="requestStatus !== 'loading'" class="no-item">No Items Found!</p>
        <p v-show="requestStatus === 'loading'" class="no-item">Loading...</p>
      </div>
      <CardGrid
        class="flex-grow-1"
        v-show="isShowingThumbnails"
        @select="onCardClicked"
        :activeItems="selectedItems"
        :items="filteredContent"
        :itemsPerRow="thumbnailItemsPerRow"
        :multi-select-mode="isMultiSelectMode || pickerModal"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import _ from 'lodash';
  import { CONTENT_REQUEST } from '@/store/actions/content';
  import {
    CONTENT_TYPE_FOLDER,
    CONTENT_TYPE_GENERAL_APP,
    CONTENT_TYPE_GENERAL_AUDIO,
    CONTENT_TYPE_GENERAL_IMAGE,
    CONTENT_TYPE_GENERAL_VIDEO,
    CONTENT_TYPE_PDF,
  } from '@/constant/const';
  import ItemList from '../ItemList.vue';
  import CardGrid from './CardGrid.vue';

  const $ = window.jQuery;

  export default {
    name: 'ContentBrowser',
    components: {
      CardGrid,
      ItemList,
    },
    props: {
      pickerModal: {
        type: Boolean,
        default: false,
      },
      allowMultipleItemSelect: {
        type: Boolean,
        default: true,
      },
      hideBreadcrumbs: {
        type: Boolean,
        default: false,
      },
      filterItemTypes: {
        type: Array,
        default: () => [],
      },
      searchTerm: {
        type: String,
        default: '',
      },
      filterImage: {
        type: Boolean,
        default: false,
      },
      filterVideo: {
        type: Boolean,
        default: false,
      },
      filterApp: {
        type: Boolean,
        default: false,
      },
      loadOnMount: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        selectedListType: 1,
        selectedAppIndex: -1,
        currentContent: [],
        directoryTree: [],
        filePreview: [],
        filePreviewPlaceholder: require('@/assets/img/content/img-placeholder.jpg'),
        videoPreviewPlaceholder: require('@/assets/img/content/video-placeholder.svg'),
        itemThumbnailPlaceholder: require('@/assets/img/content/item-thumbnail-placeholder.jpg'),
        shiftPressed: false, // check if ctrl is pressed or not, for multiple select
        selectedItems: [],
        categoryNameInput: '',
        sorted: 'ASC',
      };
    },

    mounted() {
      this.initializeContentBrowser();
    },

    watch: {
      content(newVal) {
        // watch it
        this.currentContent = newVal;

        // restore directory tree
        this.currentDirectoryTree = this.directoryTree;
        this.directoryTree = [];
        for (const directory of this.currentDirectoryTree) {
          this.openDirectoryByName(directory);
        }
      },
    },

    computed: {
      ...mapState({
        content: (state) => state.content.content,
        requestStatus: (state) => state.content.requestStatus,
        requestError: (state) => state.content.requestError,
      }),

      filteredContent() {
        return this.currentContent.filter((element) => {
          let typeFilterCondition = true;
          if (this.filterItemTypes.length && element.content_type !== CONTENT_TYPE_FOLDER) {
            const checkingType = this.getContentType(element);
            typeFilterCondition = this.filterItemTypes.indexOf(checkingType) > -1;
          }

          if (['app/text', 'app/clock', 'app/button'].includes(element.item_type)) {
            typeFilterCondition = false;
          }

          const searchTerm = this.searchTerm.toLowerCase().trim();

          return (
            (searchTerm.length ? element.name.toLowerCase().includes(searchTerm) : true) &&
            typeFilterCondition
          );
        });
      },
      isShowingThumbnails() {
        return this.selectedListType > 0;
      },
      thumbnailItemsPerRow() {
        return 6 - this.selectedListType; // i.e. 3 or 4 items per row
      },
      isMultiSelectMode() {
        return this.allowMultipleItemSelect && (this.selectedItems.length > 1 || this.shiftPressed);
      },
    },

    methods: {
      getContentType(item) {
        if (item.content_type === CONTENT_TYPE_FOLDER) {
          return CONTENT_TYPE_FOLDER;
        } else if (item.item_type.includes('image')) {
          return CONTENT_TYPE_GENERAL_IMAGE;
        } else if (item.item_type.includes('video')) {
          return CONTENT_TYPE_GENERAL_VIDEO;
        } else if (item.item_type.includes('audio')) {
          return CONTENT_TYPE_GENERAL_AUDIO;
        } else if (item.item_type.includes('pdf')) {
          return CONTENT_TYPE_PDF;
        } else if (
          item.item_type.includes('app') &&
          !item.item_type.includes('text') &&
          !item.item_type.includes('clock') &&
          !item.item_type.includes('button')
        ) {
          return CONTENT_TYPE_GENERAL_APP;
        }
      },

      initializeContentBrowser() {
        const that = this;
        if (this.$store.getters.isAuthenticated && this.loadOnMount) {
          this.reloadContent();
        }

        $(document).off('keydown.contentbrowser');
        $(document).off('keyup.contentbrowser');

        $(document).on('keydown.contentbrowser', (event) => {
          that.shiftPressed = event.shiftKey;
        });

        $(document).on('keyup.contentbrowser', (event) => {
          that.shiftPressed = event.shiftKey;
        });
      },

      reloadContent() {
        this.$store.dispatch(CONTENT_REQUEST);
      },

      onDirectoryTreeClicked(index) {
        const directoryName = this.directoryTree[index]; // name of directory just clicked
        this.openDirectoryFromBreadcrumb(directoryName);
      },

      openDirectoryFromBreadcrumb(directoryName) {
        this.$emit('item-selected', []);
        let searchingContent = this.content;
        let removeFrom = -1; // this is for removing items from directoryTree

        for (const directory of this.directoryTree) {
          const content = searchingContent.find((el) => el.name === directory);
          if (typeof content === 'undefined') return;

          searchingContent = content.contents;
          if (directoryName === directory) {
            this.currentContent = searchingContent;
            removeFrom = this.directoryTree.indexOf(directory) + 1;
          }
        }

        if (removeFrom >= 0) {
          this.directoryTree = this.directoryTree.slice(0, removeFrom);
          this.$emit('path-change', this.directoryTree.join('/'));
        }
      },

      showRootContentPage() {
        this.directoryTree = [];
        this.currentContent = this.content;
        this.$emit('item-selected', []);
        this.$emit('path-change', this.directoryTree.join('/'));
      },

      // open folder by name from current tree
      openDirectoryByName(name) {
        for (const item of this.currentContent) {
          if (item.content_type === CONTENT_TYPE_FOLDER && item.name === name) {
            this.directoryTree.push(item.name);
            this.currentCategory = item;
            this.currentContent = item.contents;
            this.$emit('path-change', this.directoryTree.join('/'));
          }
        }
      },

      onCardClicked({ event, index }) {
        // access folder
        if (this.filteredContent[index].content_type === CONTENT_TYPE_FOLDER) {
          const cat = this.filteredContent[index];
          this.currentContent = cat.contents;
          this.directoryTree.push(cat.name);
          this.currentCategory = cat;
          this.$emit('path-change', this.directoryTree.join('/'));
        } else if (!event.shiftKey || !this.allowMultipleItemSelect) {
          this.selectedItems = [index];
        } else {
          const existedIndex = this.selectedItems.indexOf(index);
          if (existedIndex !== -1) {
            this.$delete(this.selectedItems, existedIndex);
          } else {
            this.selectedItems.push(index);
          }
        }

        const items = this.selectedItems
          .map((index) =>
            typeof this.filteredContent[index] !== 'undefined'
              ? _.cloneDeep(this.filteredContent[index])
              : null,
          )
          .filter((item) => item !== null);

        this.$emit('item-selected', items);
      },

      deSelectAll() {
        this.selectedItems = [];
        this.$emit('item-selected', []);
      },

      filterToggle() {
        this.$emit('filter-toggle');
      },

      sortContent() {
        const cats = this.currentContent.filter((c) => c.content_type === CONTENT_TYPE_FOLDER);
        const files = this.currentContent.filter((c) => c.content_type !== CONTENT_TYPE_FOLDER);

        if (this.sorted === 'ASC') {
          cats.sort(this.compare);
          files.sort(this.compare);
          this.sorted = 'DESC';
        } else {
          cats.sort(this.compareD);
          files.sort(this.compareD);
          this.sorted = 'ASC';
        }
        this.currentContent = cats.concat(files);
      },

      compare(a, b) {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      },

      compareD(a, b) {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
        return 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content-browser {
    background-color: white;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    overflow-y: auto;

    .no-item {
      font-size: 1.5rem;
    }
  }

  .page-breadcrumb {
    .breadcrumb-content {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .edit-category {
    font-weight: bold;
    font-size: 0.8rem;
  }

  #editCategoryModal {
    .modal-body {
      min-width: 500px;
      padding-top: 2.5rem;
    }

    .btn {
      width: 50%;
    }
  }
</style>
