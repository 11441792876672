import { render, staticRenderFns } from "./PermissionsManagerModal.vue?vue&type=template&id=8569e304&scoped=true"
import script from "./PermissionsManagerModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./PermissionsManagerModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PermissionsManagerModal.vue?vue&type=style&index=0&id=8569e304&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8569e304",
  null
  
)

export default component.exports