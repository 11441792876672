<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: percent + '%', backgroundColor: color }"></div>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';

  const props = defineProps({
    percent: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#e3003a',
    },
  });
</script>

<style scoped lang="scss">
  .progress-bar-container {
    width: 100%;
    height: 4px;
    background-color: rgba(51, 51, 50, 0.1);
  }

  .progress-bar {
    width: 0;
    height: 4px;
    transition: width 0.3s ease;
  }
</style>
