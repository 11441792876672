<template>
  <SidebarContainer>
    <div class="saving-placeholder" :class="{ effect: isSavingLayout }">
      <img class="logo w-165" src="@/assets/img/sidebar/picflow-logo.svg" alt="PicFlow logo" />
      <div class="placeholder-text">Saving Layout</div>
    </div>

    <PlaylistItemsPanel v-if="showPlaylistItemsPanel && !isSavingLayout" />

    <div v-else class="editor-container" :class="{ effect: isSavingLayout }">
      <WidgetToolbar />

      <Tabs class="flex-grow-1" :widget="selectedWidget" />
    </div>

    <template v-if="!isSavingLayout" v-slot:buttons>
      <button
        v-if="!!selectedWidget?.assoc_id && isAdmin && selectedWidget.type != 'template'"
        class="button-2-secondary"
        @click="openWidgetPermissionsModal"
      >
        <i class="material-icons-outlined align-middle">edit_attributes</i>
        Manage Widget Permissions
      </button>

      <template v-if="!selectedWidget && isWidgetSettingTab">
        <BaseButton
          v-if="isAdmin"
          theme="secondary"
          icon="edit_attributes"
          @click.native="openLayoutPermissionsModal"
        >
          Manage Layout Permissions
        </BaseButton>
        <button type="button" class="button-2-secondary" @click="openDeleteLayoutModal">
          <i class="material-icons-outlined align-middle">delete_outline</i>
          Delete Layout
        </button>
      </template>

      <DeleteLayoutModal
        v-if="showDeleteLayoutModal"
        :layoutId="layout?.layout_id"
        @closeModal="showDeleteLayoutModal = false"
        @deleted="$router.push({ name: 'Layouts' })"
      />

      <PermissionsManagerModal
        v-if="showPermissionsModal"
        @close="showPermissionsModal = false"
        :itemId="currentPermissionManagerItemId"
        :type="currentPermissionManagerType"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import Tabs from '@/components/layoutDesigner/editor/Tabs';
  import WidgetToolbar from '@/components/layoutDesigner/editor/WidgetToolbar.vue';
  import PlaylistItemsPanel from '@/components/layoutDesigner/editor/settingPanel/playlist/PlaylistItemsPanel.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteLayoutModal from '@/components/layouts/DeleteLayoutModal.vue';
  import PermissionsManagerModal from '@/components/common/PermissionsManagerModal.vue';
  import { WIDGET_TYPES, WIDGET_TYPE_TO_ROLE_ACCESS_TYPES_MAP } from '@/models/layoutDesigner';
  import { ROLE_ACCESS_TYPES } from '@/constant/roleAccess';

  export default {
    name: 'Editor',

    components: {
      Tabs,
      WidgetToolbar,
      SidebarContainer,
      DeleteLayoutModal,
      PermissionsManagerModal,
      PlaylistItemsPanel,
    },

    data() {
      return {
        showDeleteLayoutModal: false,
        showPermissionsModal: false,
        currentPermissionManagerType: null,
        currentPermissionManagerItemId: null,
        ROLE_ACCESS_TYPES,
      };
    },

    computed: {
      selectedWidget() {
        return this.$store.state.layoutDesigner.selectedWidget;
      },

      showPlaylistItemsPanel() {
        const { playlistOnEditMode } = this.$store.state.layoutDesigner;

        return playlistOnEditMode && this.selectedWidget?.type === WIDGET_TYPES.PLAYLIST;
      },

      layout() {
        return this.$store.state.layoutDesigner.layout;
      },

      isWidgetSettingTab() {
        return this.$store.state.layoutDesigner.isWidgetSettingTab;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator || this.$store.getters.isOrganizationAdmin;
      },

      isSavingLayout() {
        return this.$store.state.layoutDesigner.isSavingLayout;
      },
    },

    methods: {
      openDeleteLayoutModal() {
        this.showDeleteLayoutModal = true;
      },

      openPermissionsModal() {
        this.showPermissionsModal = true;
      },

      openLayoutPermissionsModal() {
        this.currentPermissionManagerItemId = this.layout.layout_id;
        this.currentPermissionManagerType = ROLE_ACCESS_TYPES.LAYOUT;
        this.openPermissionsModal();
      },

      openWidgetPermissionsModal() {
        if (!this.selectedWidget) return;

        const { type } = this.selectedWidget;
        this.currentPermissionManagerItemId =
          type === WIDGET_TYPES.PLAYLIST
            ? this.selectedWidget.object.playlist_id
            : this.selectedWidget.object.item_id;
        this.currentPermissionManagerType = WIDGET_TYPE_TO_ROLE_ACCESS_TYPES_MAP[type];
        this.openPermissionsModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-settings .editor-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &.effect {
      display: none;
    }
  }

  .saving-placeholder {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    animation: skeleton-content 3s infinite;

    img {
      width: 80%;
    }

    .placeholder-text {
      font-size: 24px;
    }

    &.effect {
      display: flex;
    }
  }
</style>
