<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="widget-item text-widget" :style="style">
      <div class="w-100 h-100 text-widget-text" :id="widget.object.wid">
        <div v-if="widget.object.headerOn" class="header-container" :style="headerStyle">
          {{ widget.object.headerText }}
        </div>

        <div
          v-if="!widget.object.ticker"
          ref="text_field"
          :class="[{ 'auto-fit-multiline-text': widget.object.maximalTextSize }]"
          :style="bodyStyle"
          v-html="this.getUserText()"
        ></div>

        <marquee
          v-else-if="marqueeMode && !resetMarquee"
          class="marquee-container"
          :scrollamount="marqueeSpeed"
          truespeed
          :style="bodyStyle"
        >
          {{ userText }}
        </marquee>

        <div v-else-if="resetMarquee" :style="bodyStyle"></div>

        <div
          v-if="isTicker && !marqueeMode"
          ref="feed_carousel"
          class="feed-carousel carousel slide h-100"
          data-ride="carousel"
          data-touch="true"
          data-wrap="true"
          data-pause="hover"
        >
          <div class="carousel-inner h-100" :style="bodyStyle">
            <div
              v-for="(line, idx) in textLines"
              :key="idx"
              class="carousel-item h-100"
              :class="{ active: idx === 0 || textRendering }"
            >
              <div class="feed-carousel-item h-100 w-100 widget-specific-auto-fit-text">
                <span class="centerText">{{ line }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';
  import { DEFAULT_TEXT_WIDGET } from '@/models/layoutDesigner';

  const $ = window.jQuery;

  export default {
    name: 'TextWidget',

    mixins: [simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
    },

    props: {
      widget: {
        type: Object,
      },
    },

    data() {
      return {
        textLines: [],
        textRendering: true,
        resetMarquee: false,
      };
    },

    mounted() {
      this.renderWidget();
    },

    watch: {
      isTicker() {
        this.renderWidget();
      },

      tickerDuration() {
        this.renderWidget();
      },

      userText() {
        this.renderWidget();
      },

      marqueeMode() {
        this.renderWidget();
      },

      marqueeSpeed() {
        this.resetMarquee = true;

        setTimeout(() => (this.resetMarquee = false), 1);
      },
    },

    computed: {
      bodyStyle() {
        return {
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.backgroundOpacity / 100,
          ),
          color: getRBGAColor(this.widget.object.textColor, this.widget.object.opacity / 100),
          fontFamily: `${this.widget.object.textFont}, Tahoma, Verdana, sans-serif`,
          fontSize: `${this.scaleTemplateValue(this.widget.object.textSize)}px`,
          height: '100%',
          padding: `${this.scaleTemplateValue(this.widget.object.paddingText)}px`,
          textAlign: this.widget.object.alignment,
        };
      },

      headerStyle() {
        return {
          color: getRBGAColor(
            this.widget.object.headerTextColor,
            this.widget.object.headerTextOpacity / 100,
          ),
          backgroundColor: getRBGAColor(
            this.widget.object.headerBackgroundColor,
            this.widget.object.headerBackgroundOpacity / 100,
          ),
          fontFamily: `${this.widget.object.textFont}, Tahoma, Verdana, sans-serif`,
          fontSize: `${this.scaleTemplateValue(this.widget.object.headerTextSize)}px`,
          justifyContent: `${this.widget.object.headerTextAlignment}`,
          marginBottom: `${this.scaleTemplateValue(this.widget.object.headerTextSeparation)}px`,
          padding: `${this.scaleTemplateValue(this.widget.object.paddingHeader)}px`,
          textAlign: this.widget.object.headerTextAlignment,
        };
      },

      style() {
        return {
          width: '100%',
          height: '100%',
          lineHeight: 1,
          borderRadius: `${this.widget.object.borderRadius}px`,
          zIndex: 100 + this.widget.position.zIndex,
        };
      },

      position() {
        return this.getPosition(this.widget);
      },

      isTicker() {
        return this.widget.object.ticker;
      },

      userText() {
        return this.widget.object.userText;
      },

      tickerDuration() {
        return this.widget.object.tickerDuration;
      },

      marqueeMode() {
        return !!this.widget.object.marqueeMode;
      },

      marqueeSpeed() {
        return this.widget.object.marqueeSpeed || DEFAULT_TEXT_WIDGET.marqueeSpeed;
      },
    },

    methods: {
      getUserText() {
        return this.userText ? this.userText.replace(/\n/g, '<br />') : '';
      },

      renderWidget() {
        if (!this.isTicker) return;

        this.setTickerLines();

        if (!this.marqueeMode) {
          this.setupCarousel(this.widget.object);
        }
      },

      setTickerLines() {
        const { userText } = this.widget.object;
        this.textLines = userText ? userText.split('\n') : [];
      },

      setupCarousel(widget) {
        this.$nextTick(() => {
          $(this.$refs.feed_carousel).find('.widget-specific-auto-fit-text');
          this.textRendering = false;

          this.$nextTick(() => {
            $(this.$refs.feed_carousel).carousel('dispose');
            $(this.$refs.feed_carousel).carousel({
              interval:
                (widget.tickerDuration && widget.tickerDuration > 0 ? widget.tickerDuration : 5) *
                1000,
            });
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-widget {
    position: absolute;
  }

  .text-widget-text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
</style>
