<template>
  <div class="layer-widget-panel scrollbar">
    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />

        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="form-group template-input name">
      <label for="template-name">Widget Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        maxlength="40"
        placeholder="Name"
        :disabled="isWidgetLockedByOverride"
        v-model="value.object.name"
      />
    </div>

    <div class="form-group">
      <BaseInput label="Address">
        <Multiselect
          :options="organisationAddresses"
          track-by="name"
          label="name"
          v-model="value.object.tenants.address"
          placeholder="Select an Address"
          :searchable="true"
          :allow-empty="false"
          open-direction="bottom"
          :loading="loadingAddresses"
          :showLabels="false"
          @select="handleAddressChanged"
        >
          <template slot="option" slot-scope="{ option }">
            <div>{{ option.name }}</div>
          </template>
          <template slot="noResult">
            <span>No addresses found</span>
          </template>
          <template slot="noOptions">
            <span>No addresses found</span>
          </template>
        </Multiselect>
      </BaseInput>
    </div>

    <div v-show="value.object.tenants.floors" class="form-group">
      <div class="form-label">Select floors</div>
      <div class="floors-container scrollbar">
        <div class="all-floors">
          <div class="floor-title">All floors</div>
          <div class="floor-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="all-floors"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.tenants.allFloorsVisible"
              />
              <label class="custom-control-label" for="all-floors"></label>
            </div>
          </div>
        </div>

        <draggable v-model="value.object.tenants.floors">
          <div v-for="(floor, index) in value.object.tenants.floors" :key="index" class="floor">
            <div class="left-section">
              <div class="drag-icon">
                <i class="material-icons-outlined align-middle">dehaze</i>
              </div>
              <input
                class="floor-title"
                type="text"
                :value="floor.displayFloorName || `VÅNING ${floor.floor}`"
                minlength="3"
                maxlength="40"
                @input="(event) => updateFloorDisplayName(floor, event)"
              />
            </div>
            <div class="floor-switch">
              <div class="custom-control custom-switch check">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`${floor}-${index}`"
                  :disabled="isWidgetLockedByOverride"
                  v-model="floor.visible"
                />
                <label class="custom-control-label" :for="`${floor}-${index}`"></label>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>

    <div class="form-group">
      <div class="form-label">Widget Type</div>
      <div class="buttons-container">
        <button
          type="button"
          :class="{
            'button-2-primary': !value.object.tenants.verticalMode,
            'button-2-secondary': value.object.tenants.verticalMode,
          }"
          @click="setVerticalMode(false)"
        >
          Horizontal
        </button>

        <button
          type="button"
          :class="{
            'button-2-primary': value.object.tenants.verticalMode,
            'button-2-secondary': !value.object.tenants.verticalMode,
          }"
          @click="setVerticalMode(true)"
        >
          Vertical
        </button>
      </div>
    </div>

    <div class="form-group form-range layout-input">
      <div class="slider-top">
        <div class="form-label">Number of floors on 1 slide</div>
        <div class="value">{{ value.object.tenants.floorsOnSlide }}</div>
      </div>
      <div class="slider-container">
        <BaseSlider
          :value="value.object.tenants.floorsOnSlide"
          :min="1"
          :max="12"
          @update:value="updateTenantsFloors"
        />
      </div>
    </div>

    <div class="form-group form-range layout-input">
      <div class="slider-top">
        <div class="form-label">Interval (seconds)</div>
        <div class="value">{{ value.object.tenants.interval }}</div>
      </div>
      <div class="slider-container">
        <BaseSlider
          :value="value.object.tenants.interval"
          :min="1"
          :max="120"
          @update:value="updateTenantsInterval"
        />
      </div>
    </div>

    <div class="form-group layout-input time">
      <div class="sub-section-title">Refresh every</div>
      <div class="time-container">
        <input class="form-control" type="number" id="layer-time" min="0" v-model="refreshTime" />

        <select
          class="custom-select"
          id="layer-time-unit"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.tenants.timeUnit"
          style="zoom: 1.1; height: 38px"
        >
          <option :value="TIME_UNITS.SECONDS.value" style="zoom: 1.1">
            {{ TIME_UNITS.SECONDS.title }}
          </option>
          <option :value="TIME_UNITS.MINUTES.value" style="zoom: 1.1">
            {{ TIME_UNITS.MINUTES.title }}
          </option>
          <option default :value="TIME_UNITS.HOURS.value" style="zoom: 1.1">
            {{ TIME_UNITS.HOURS.title }}
          </option>
          <option :value="TIME_UNITS.DAYS.value" style="zoom: 1.1">
            {{ TIME_UNITS.DAYS.title }}
          </option>
        </select>
      </div>
    </div>

    <div class="section-title">Visual Appearance</div>

    <div class="sub-section-title">Background</div>
    <div class="form-group hidden">
      <div class="form-row background-css">
        <color-picker
          class="col"
          input-el-id="layer-background-color"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.tenants.backgroundColor"
        />

        <div class="input-group col">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="layer-opacity"
            min="1"
            max="100"
            :disabled="isWidgetLockedByOverride"
            v-model="backgroundOpacity"
          />
        </div>
      </div>
    </div>

    <div class="title-row">
      <div class="font-container form-group layout-input">
        <label for="template-name">Title Font</label>
        <FontSelector
          :value="value.object.tenants.titleFont"
          :fonts="userFonts"
          :relative="false"
          @update:value="(font) => updateTenantsFont(font, 'titleFont')"
        ></FontSelector>
      </div>

      <div class="template-input form-group">
        <label for="text-size">Text Size</label>

        <input
          type="number"
          class="form-control"
          id="text-size"
          :disabled="isWidgetLockedByOverride"
          min="4"
          max="120"
          v-model="titleTextSize"
        />
      </div>

      <div class="color-section" v-click-outside="closeTitleColorPicker">
        <div
          class="color-button"
          @click="openColorPicker('titleBackgroundColor', 'title')"
          title="Background Color"
        >
          <img
            class="logo w-165"
            src="@/assets/icon/Colors.svg"
            alt="Room's Number Background Color"
          />
          <div
            class="color-bar"
            :style="{ backgroundColor: value.object.tenants.titleBackgroundColor }"
          ></div>
        </div>

        <div
          class="color-button"
          @click="openColorPicker('titleFontColor', 'title')"
          title="Title Font Color"
        >
          <i class="material-icons-outlined align-middle">text_fields</i>
          <div
            class="color-bar"
            :style="{ backgroundColor: value.object.tenants.titleFontColor }"
          ></div>
        </div>

        <Sketch
          v-if="showTitleColorPicker"
          class="picker"
          :value="value.object.tenants[pickerColorKey]"
          @input="updateColor"
        />
      </div>
    </div>

    <div class="floor-row">
      <div class="font-container form-group layout-input">
        <label for="template-name">Floors Font</label>
        <FontSelector
          :value="value.object.tenants.floorFont"
          :fonts="userFonts"
          :relative="false"
          @update:value="(font) => updateTenantsFont(font, 'floorFont')"
        ></FontSelector>
      </div>

      <div class="template-input form-group">
        <label for="text-size">Text Size</label>

        <input
          type="number"
          class="form-control"
          id="text-size"
          :disabled="isWidgetLockedByOverride"
          min="4"
          max="120"
          v-model="floorTextSize"
        />
      </div>

      <div class="color-section" v-click-outside="closeFloorsColorPicker">
        <div
          class="color-button"
          @click="openColorPicker('floorFontColor', 'floors')"
          title="Floors Font Color"
        >
          <i class="material-icons-outlined align-middle">text_fields</i>
          <div
            class="color-bar"
            :style="{ backgroundColor: value.object.tenants.floorFontColor }"
          ></div>
        </div>

        <Sketch
          v-if="showFloorsColorPicker"
          class="picker"
          :value="value.object.tenants[pickerColorKey]"
          @input="updateColor"
        />
      </div>
    </div>

    <div class="room-row">
      <div class="font-container form-group layout-input">
        <label for="template-name">Rooms Font</label>
        <FontSelector
          :value="value.object.tenants.roomsFont"
          :fonts="userFonts"
          :relative="false"
          @update:value="(font) => updateTenantsFont(font, 'roomsFont')"
        ></FontSelector>
      </div>

      <div class="template-input form-group">
        <label for="text-size">Text Size</label>

        <input
          type="number"
          class="form-control"
          id="text-size"
          :disabled="isWidgetLockedByOverride"
          min="4"
          max="120"
          v-model="numberTextSize"
        />
      </div>

      <div class="color-section" v-click-outside="closeRoomsColorPicker">
        <!-- <div
          class="color-button"
          @click="openColorPicker('numberBackgroundColor', 'rooms')"
          title="Background Color"
        >
          <img
            class="logo w-165"
            src="@/assets/icon/Colors.svg"
            alt="Room's Number Background Color"
          />
          <div
            class="color-bar"
            :style="{ backgroundColor: value.object.tenants.numberBackgroundColor }"
          ></div>
        </div> -->

        <div
          class="color-button"
          @click="openColorPicker('titleFontColor', 'rooms')"
          title="Room's Number Font Color"
        >
          <i class="material-icons-outlined align-middle">text_fields</i>
          <div
            class="color-bar"
            :style="{ backgroundColor: value.object.tenants.titleFontColor }"
          ></div>
        </div>

        <Sketch
          v-if="showRoomsColorPicker"
          class="picker"
          :value="value.object.tenants[pickerColorKey]"
          @input="updateColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Sketch } from 'vue-color';
  import ClickOutside from 'vue-click-outside';
  import { Multiselect } from 'vue-multiselect';
  import draggable from 'vuedraggable';

  import ColorPicker from '@/components/ColorPicker';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';
  import { BaseSlider } from '@ui/atoms/baseSlider';

  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';
  import { restrictToBounds } from '@/helpers/draggable';

  import { TIME_UNITS } from '@/constant/const';
  import { apiGetAddresses, apiGetDetailedAddress } from '@/api/tenants';

  export default {
    name: 'TenantsWidgetPanel',

    mixins: [widgetMixin],

    directives: {
      ClickOutside,
    },

    components: {
      ColorPicker,
      PixelIcon,
      LockIcon,
      BaseSlider,
      FontSelector,
      Sketch,
      Multiselect,
      draggable,
    },

    data() {
      return {
        TIME_UNITS,
        showTitleColorPicker: false,
        showFloorsColorPicker: false,
        showRoomsColorPicker: false,
        pickerColorKey: null,
        organisationAddresses: [],
        loadingAddresses: false,
      };
    },

    created() {
      this.getAddresses();
    },

    computed: {
      userFonts() {
        return this.$store.getters.userFonts;
      },

      refreshTime: {
        get() {
          return this.value.object.tenants.refreshTime && this.value.object.tenants.refreshTime <= 0
            ? 1
            : this.value.object.tenants.refreshTime;
        },
        set(value = 0) {
          const refreshTime = restrictToBounds(!!value ? value : 0, 0, Infinity);
          this.$set(this.value.object.tenants, 'refreshTime', Number(refreshTime));
        },
      },

      backgroundOpacity: {
        get() {
          return this.value.object.tenants.opacity;
        },
        set(value = 1) {
          const refreshTime = restrictToBounds(value, 1, 100);
          this.$set(this.value.object.tenants, 'opacity', Number(refreshTime));
        },
      },

      titleTextSize: {
        get() {
          return this.value.object.tenants.titleFontSize;
        },
        set(value) {
          this.$set(
            this.value.object.tenants,
            'titleFontSize',
            restrictToBounds(Number(value), 4, 120),
          );
        },
      },

      floorTextSize: {
        get() {
          return this.value.object.tenants.floorFontSize;
        },
        set(value) {
          this.$set(
            this.value.object.tenants,
            'floorFontSize',
            restrictToBounds(Number(value), 4, 120),
          );
        },
      },

      numberTextSize: {
        get() {
          return this.value.object.tenants.numberFontSize;
        },
        set(value) {
          this.$set(
            this.value.object.tenants,
            'numberFontSize',
            restrictToBounds(Number(value), 4, 120),
          );
        },
      },
    },

    methods: {
      async fetchTenants() {
        try {
          const response = await apiGetDetailedAddress(this.value.object.tenants.address?.id);
          const floors = response.floors.map((floor) => ({ ...floor, visible: true }));

          this.$set(this.value.object.tenants, 'floors', floors);
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Unabled to get tenants information.',
          });
        }
      },

      updateFloorDisplayName(floor, event) {
        let { value } = event.target;

        if (value.length > 40) {
          value = value.slice(0, 40);
        }

        this.$set(floor, 'displayFloorName', value);
      },

      async getAddresses() {
        try {
          this.loadingAddresses = true;
          const response = await apiGetAddresses();

          this.organisationAddresses = response.map((building) => ({
            id: building.externalId,
            name: building.streetAddress,
          }));
        } catch (error) {
          this.$toasted.global.general_error({
            message: "Error getting your organisation's addresses",
          });
        }

        this.loadingAddresses = false;
      },

      updateTenantsInterval(value) {
        this.$set(this.value.object.tenants, 'interval', value);
      },

      handleAddressChanged() {
        this.$set(this.value.object.tenants, 'allFloorsVisible', true);
        this.fetchTenants();
      },

      updateTenantsFloors(value) {
        this.$set(this.value.object.tenants, 'floorsOnSlide', value);
      },

      updateTenantsFont(newFont, property) {
        this.$set(this.value.object.tenants, property, newFont);
      },

      updateColor(color) {
        if (!this.pickerColorKey) return;

        this.$set(this.value.object.tenants, this.pickerColorKey, color.hex);
      },

      openColorPicker(colorKey, type) {
        if (this.isWidgetLockedByOverride || !type) return;

        if (type === 'title') {
          this.showTitleColorPicker = true;
        } else if (type === 'floors') {
          this.showFloorsColorPicker = true;
        } else if (type === 'rooms') {
          this.showRoomsColorPicker = true;
        } else {
          return;
        }

        this.pickerColorKey = colorKey;
      },

      closeTitleColorPicker() {
        if (!this.pickerColorKey || !this.showTitleColorPicker) return;

        this.showTitleColorPicker = false;
        this.pickerColorKey = null;
      },

      closeRoomsColorPicker() {
        if (!this.pickerColorKey || !this.showRoomsColorPicker) return;

        this.showRoomsColorPicker = false;
        this.pickerColorKey = null;
      },

      closeFloorsColorPicker() {
        if (!this.pickerColorKey || !this.showFloorsColorPicker) return;

        this.showFloorsColorPicker = false;
        this.pickerColorKey = null;
      },

      setVerticalMode(isVertical = false) {
        this.$set(this.value.object.tenants, 'verticalMode', isVertical);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }
  }

  .picker {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 20;
  }

  .time-container {
    display: flex;
    gap: 16px;
  }

  .section-title {
    font-weight: bold;
    padding-top: 16px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: $tableText;
  }

  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .slider-container {
    width: 100%;
    padding-right: 16px;
  }

  .floors-container {
    border-radius: 8px;
    padding: 10px;
    border: 2px solid $borderGrey;
    max-height: 200px;
    overflow-y: auto;

    .custom-switch {
      margin-right: -8px;
    }

    .all-floors {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 8px;

      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: $primaryText;
    }

    .floor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      &:hover {
        background-color: $highlightGrey;
      }

      .left-section {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
        width: 100%;
      }

      .drag-icon {
        padding: 2px;

        i {
          color: $secondaryText;
          opacity: 0.8;
          transform: scaleX(0.6);
          cursor: move;
        }
      }

      input.floor-title {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        color: $primaryText;
        border: none;
        outline: none !important;
        background: none;
        text-overflow: ellipsis;
      }
    }
  }

  .form-range {
    display: flex;
    gap: 16px;
    flex-direction: column;

    &.form-group {
      margin-bottom: 20px;
    }
  }

  .time-container {
    display: flex;
    gap: 16px;
  }

  .color-section {
    display: flex;
    gap: 10px;
    align-items: end;
    position: relative;
  }

  .title-row,
  .room-row,
  .floor-row {
    display: grid;
    position: relative;
    gap: 10px;
    margin: 0 0 1rem;

    .form-group {
      margin-bottom: 0;
    }
  }

  .floor-row,
  .room-row {
    grid-template-columns: 170px 70px 40px;
  }

  .title-row {
    grid-template-columns: 120px 70px 90px;
  }
</style>
