<script lang="ts">
  export default {
    name: 'CreateContentFolderModal',
  };
</script>

<script setup lang="ts">
  import { toRefs, ref, defineEmits, withDefaults, defineProps, onMounted } from 'vue';
  import SimpleModal from '@/components/common/SimpleModal.vue';
  import { useToasted } from '@/helpers';
  import { apiCreateFolder } from '@/api/content';

  const props = withDefaults(
    defineProps<{
      parentFolder?: number | null;
    }>(),
    { parentFolder: null },
  );
  const { parentFolder } = toRefs(props);

  const emit = defineEmits<{
    (event: 'close', data: { shouldRehydrate: boolean }): void;
    (event: 'success', data: { shouldRehydrate: boolean }): void;
  }>();

  const toasted = useToasted();

  const modalRef = ref(null);

  const folderName = ref('');
  const isLoading = ref(false);
  const shouldRehydrate = ref(false);

  onMounted(() => {
    if (modalRef.value) {
      modalRef.value.open();
    }
  });

  async function handleCreateFolder() {
    if (isLoading.value) return;

    if (folderName.value === null || folderName.value === '') {
      toasted.global.general_error({
        message: 'Content folder name cannot be empty',
      });
      return;
    }

    try {
      isLoading.value = true;
      shouldRehydrate.value = false;

      await apiCreateFolder(folderName.value, parentFolder.value);

      toasted.global.general_success({
        message: 'Created new content folder',
      });

      shouldRehydrate.value = true;
      emit('success', { shouldRehydrate: shouldRehydrate.value });

      if (modalRef.value) {
        modalRef.value.close();
      }
    } catch (error) {
      toasted.global.general_error({
        message: 'Failed to create new content folder',
      });

      shouldRehydrate.value = false;
    } finally {
      isLoading.value = false;
    }
  }

  function handleClose() {
    emit('close', { shouldRehydrate: shouldRehydrate.value });
  }
</script>

<template>
  <SimpleModal ref="modalRef" title="Create Content Folder" @close="handleClose">
    <template #default>
      <div class="content-wrapper">
        <BaseInput v-model="folderName" label="Name" placeholder="Enter folder name" />
      </div>
    </template>
    <template #footer>
      <BaseButton size="sm" class="mr-4" theme="secondary" @click.native="handleClose">
        Cancel
      </BaseButton>
      <BaseButton size="sm" :loading="isLoading" @click.native="handleCreateFolder">
        Create Folder
      </BaseButton>
    </template>
  </SimpleModal>
</template>

<style scoped lang="scss">
  ::v-deep .ui-modal-content {
    width: 500px !important;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
